
import React, { useRef, useEffect, useState } from 'react';

import mapboxgl from 'mapbox-gl';

import '../../static/css/globeMap.css'
mapboxgl.accessToken = 'pk.eyJ1IjoiaGFtaWRoYW56YSIsImEiOiJja2hkOHZtaWYwNGg1MnducjV4Y29pajNsIn0.ZaZ4QARagSXH20Ws3Nnn6g';

export const GlobeMap = () => {
    const mapContainerRef = useRef(null);
  
    const [lng, setLng] = useState(5);
    const [lat, setLat] = useState(34);
    const [zoom, setZoom] = useState(1.5);
  
    // Initialize map when component mounts
    useEffect(() => {
      const map = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [lng, lat],
        zoom: zoom
      });
  
      // Add navigation control (the +/- zoom buttons)
      map.addControl(new mapboxgl.NavigationControl(), 'top-right');
      map.on('load', ()=> {
          map.resize();
    });
      map.on('move', () => {
        setLng(map.getCenter().lng.toFixed(4));
        setLat(map.getCenter().lat.toFixed(4));
        setZoom(map.getZoom().toFixed(2));

      });
  
      // Clean up on unmount
      return () => map.remove();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
    return (
      <div style={{width:'70%'}}>
        <div className='sidebarStyle'>
          <div>
            Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
          </div>
        </div>
        <div className='map-container' ref={mapContainerRef} />
      </div>
    );
  };

