import React from 'react'
/* import { Route } from 'react-router-dom' */
import { useSelector, useDispatch, shallowEqual } from "react-redux"

import  {
    setCurrentFeature,
    setFetching,
    currentFeature,
    isFetching,
    isLogedIn,
 }from './masterSlice';
 //import { graph_spinner} from './components/spinner_progress'
import {SignInSignup} from '../accounts/components/signup-signin'
 import { SciNews }  from '../news/sciNews'
 import { Maps }  from '../mapsAndCharts/components/sc1map'
 import {SingleBOK} from '../singleBok/sciSingleBok'
 import {WikiBOK} from '../wiki_kd/sciWikiBok'
 import {SciPS} from '../predictiveStates/sciPS'
 import {WebBOK} from '../web_bok/sciWebBok'
 
 export const MasterApp = ()=> {
    const current_feature = useSelector(currentFeature);
    const logedin=useSelector(isLogedIn)

    //console.log(current_feature)
    const dispatch = useDispatch();
    const progress =useSelector(isFetching)
  
    return (
      logedin?<div style={{display:'flex', flexDirection:'column'}}>
        {current_feature ==='news'? <SciNews/>:''}
        {current_feature ==='maps'? <Maps/>:''}
        {current_feature ==='single_bok'? <SingleBOK/>:''}
        {current_feature ==='wiki_bok'? <WikiBOK/>:''}
        {current_feature ==='predictive_states'? < SciPS/>:''}
       {current_feature ==='web_bok'? <WebBOK/>:''}
        </div>:
        <SignInSignup/>
    )}
export default MasterApp