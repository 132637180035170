
import React from 'react'

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { capitalize, TrimMyString} from  '../../news/utils/newsUtilFuncs'
//import {current_bok} from '../singleBokSlice'

//import '../../news/static/css/news_item.css'
//import '../../news/static/css/search_news.css'
//import '../../news/static/css/news_columns.css'
import '../static/css/webBok_Kd.css'
import {TopNav } from '../../news/layout_components/topNav'
import {WebItemCards} from './web_bok_cards'


export const WebBokSum = (props) =>{
    const dispatch= useDispatch()
    //const cur_bok =useSelector(current_bok)
    //console.log('CURRENT webboksum input is',props)
    
        return(<div className="web-container" id="web-container">
        <div id="web-columns">
{props.cur_items.map((item, idx) =><WebItemCards card_props={item} card_idx={idx}/>)}
             </div></div>)
    }
