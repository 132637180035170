import React from 'react'
/* import { Route } from 'react-router-dom' */
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import {capitalize}  from '../../news/utils/newsUtilFuncs'

export const SbRightNav = (props) =>{

    const handleSearch = (e) =>{
        let heading_cat= document.getElementById('bok_name').textContent.trim().split(' ').join('-')
        let sub_head_cat = e.target.dataset.head.trim().split(' ').join('-')
        let query= heading_cat+'&'+sub_head_cat
        props.handelSbConverse (query)
        //alert('you want to search about' , query)
      }

      const display_head_item = (item) =>{
        const hc =item.replace(/[^\w\.\s]/gi, '')
      if(hc){return (<div class="sci-btn news_head_btn" data-head={item} onClick={e=>handleSearch(e)} >{hc}</div>)
      }}

return(
  
<div id="right-nav">

    <div style={{backgroundColor:"#212020", padding:"5%", color:"whitesmoke", position:"sticky", fontWeight:"bold", fontSize: "1.1rem"}}>
      <p>INFO NUGGETS FOR:&nbsp;<span id="bok_name" className="main_news_head" data-head={''} style={{color:"rgb(191, 196, 21)"}} >{capitalize(props.head_query)}</span>
         </p>
</div>
        <div className="" id="news_heads">
          {props.HC? props.HC.map(item => display_head_item(item)):null}
        </div>
</div>

)
  }