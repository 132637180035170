import axios from 'axios';
import {axiosInstance} from '../../../axiosApi';
import {API_URL_BASE} from "../../../url_settings";


const ROOT_URL = window.location.protocol + "//" + window.location.host
console.log(' THIS IS ROOT_URL:', ROOT_URL)
const app_adress='/wikiBok/'
const API_URL=API_URL_BASE+app_adress
console.log(' THIS IS API_URL:', API_URL)
//const csrftoken = document.querySelector('[name=csrfmiddlewaretoken]').value;
//console.log(' THIS IS CSRF TOCKEN:', csrftoken)

export default class WikiBokService {
	getWikiRes(doc_data, timeout_option) {
		const url = API_URL ;
		console.log('Im agoing to fetch this from wiki back', url)
		return axiosInstance.post(url, doc_data).then(response => response.data);
	};
	searchSingle(query) {
        if (!query) {query = ''}
        const url = `${API_URL}/search/`+ query;
		return axiosInstance.get(url).then(response => response.data);
	};
	converseSingle(query) {
		const url = `${API_URL}/converse/` + query;
		return axiosInstance.get(url, {
			//crossdomain: true
		}).then(response => response.data);
	}
	graphSingle(query) {
		const url = `${API_URL}/graphs/` + query;
		return axiosInstance.get(url, {
			//crossdomain: true
		}).then(response => response.data);
	}

}