
import React , { useState,  useEffect, useRef } from 'react'

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { capitalize, TrimMyString} from  '../news/utils/newsUtilFuncs'

import {GrGraphQl} from'react-icons/gr'
//import './static/css/wikiBok_Kd.css'
import '../web_bok/static/css/webBok_Kd.css'


//import {ProgSpinner} from '../master/components/spinner_progress'
import {PogressBarLinear} from '../master/components/linearProgressbar'
import {isFetching} from '../master/masterSlice'

import {TopNav } from '../news/layout_components/topNav'
import { LeftNav } from '../news/layout_components/leftNav'

import {RightNav} from '../news/layout_components/rightNav'

import {NewsItemCards} from '../news/components/newsCards'
import { NewsHeadsTop } from '../news/components/newsHeadsAtTop'
import {NewsConverse} from '../news/components/newsConverse'

import {WikiBokGraphs} from './components/wiki_bok'
import {WikiBokSum} from './components/wiki_bok_sum'
import {TreeGraph} from '../master/components/graphComponents/newsTreeMap2'

import {wikiServices,
    setCurrentWikiBok,
    setExploredWikiBoks,
    setCurrentWikiBokFeature,
    explored_wiki_boks,
    setCurrentWikiBokMode,
    current_wiki_bok,
    current_wiki_bok_feature,
    current_wiki_bok_mode,
  } from './wikiBokSlice.js' 

import{
    setFetching,
 }from '../master/masterSlice'

 export const WikiBOK = () =>{
    const dispatch= useDispatch()
    const progress=useSelector(isFetching)
    //const cur_bok= useSelector(state =>state.singleBok.current_bok,  shallowEqual)
    const cur_bok =useSelector(current_wiki_bok)
    // console.log('Cuurent wiki bok is:', cur_bok)
    //const cur_bok ={'doc_url':'khar', 'res':'khar'}
    //const current_bok_feature = useSelector(state =>state.singleBok.current_bok_feature,  shallowEqual)
    //const cur_bok_feature={'feature':'sb', 'mode':'top_cats'}
    const cur_bok_feature=useSelector(current_wiki_bok_feature)
    const cur_bok_invst_mode=useSelector(current_wiki_bok_mode)
    // console.log('Cuurent wiki bok feature is:', cur_bok_feature)
    // console.log('Cuurent web bok MODE is:', cur_bok_invst_mode)

    const active_mode_btn = (invst_mode) =>{
      if(document.querySelectorAll('.web_btn')){document.querySelectorAll('.web_btn').forEach(item =>{ 
        if (item.dataset.invstKey === invst_mode){item.style.color='rgb(127, 211, 1)'} else{item.style.color='whitesmoke'}}
      )}
      window.scrollTo({top: 0, behavior: 'smooth'})}

      useEffect(() => {    active_mode_btn(cur_bok_invst_mode) });

    const canvas_ref=useRef()

    const fetchWb = (doc_data) =>{
        console.log(doc_data)
        dispatch(setFetching())
        wikiServices.SbConverseRes(doc_data).then(resp =>{
          //  console.log('Got resultfrom back for single doc', resp);
           dispatch(setExploredWikiBoks({'doc_url':resp.url, 'res':resp.res}))
           dispatch(setFetching())
      })}
    const handelWbEnter =(e)=>{
        let code = (e.keyCode ? e.keyCode : e.which);
      if(code === 13) {const query =cur_bok.doc_url+'&'+ e.target.value;
      fetchWb(query); document.getElementById('converseBar').value=''
      }
    }

    const wiki_tools2 =() =>{
      const graph_modes =[['P_TREE', 'P_Tree Graphs'],['LP_TREE','LP_Tree Graphs'], ['NIC_TREE', 'Info Tree Graphs' ]]

        return(<div className='sci-container' style={{backgroundColor:'rgb(33, 32, 32)'}}><div className='' style={{width:'80%', margin:'auto'}}>
        <div className='sci-converse-head' ><span style={{margin:'2px', backgroundColor:'rgb(33, 32, 32)'}}>  Maps:</span> 
        <div className='sci-converse-client-input-container' style={{width:'50%'}}>
        <div className='sci-converse-client-input' >
      <input id="converseBar" name="" type="search" required placeholder="find out news relations" onKeyUp = { e => handelWbEnter(e)}/>
      </div><div><GrGraphQl  size='32px' style={{ marginLeft:5, marginTop:'2px',color: "rgb(228, 226, 100)" }}/></div>
      </div>
      <div class="" style={{margin:'5px 2rem', cursor:"pointer"}} data-head={''} onClick={e=> dispatch(setCurrentWikiBokFeature({feature:'BAR', mode:''})) }>
        {cur_bok_feature.feature === 'BAR'?<span style={{ color:'#d9df13', textDecoration:"underline"}}> Bar Graphs</span>:<span>Bar Graphs</span>}</div>
      
      { graph_modes.map(g_item=>
      <div class="" id={g_item[0]} data-graph-key={g_item[0]} style={{margin:'5px 2rem', cursor:"pointer"}}  
      onClick={e=> dispatch(setCurrentWikiBokFeature({feature:e.target.dataset.graphKey, mode:''}))}> {g_item[1]}</div>)
      }
        <div class="" style={{margin:'5px 2rem', cursor:"pointer"}} data-head={''} onClick={e=> dispatch(setCurrentWikiBokFeature({feature:'WIKIB', mode:''}))}>
        {cur_bok_feature.feature === 'WB'?<span style={{ color:'#d9df13', textDecoration:"underline"}}>Wiki Bok Summary</span>: <span> Wiki Bok Summary</span>}</div>
      </div></div>
      </div>)}

const wiki_tools = (cur_bok_res) =>{ 
  return(<div id="left-nav" style={{backgroundColor:'whitesmoke'}}><div id="news_heads" style={{backgroundColor:'whitesmoke', padding: "1rem 5% 10vh 7%"}}>
{Object.keys(cur_bok_res).map(k_item =><div class="web_btn" id={k_item} data-invst-key={k_item} onClick={e =>dispatch(setCurrentWikiBokMode(e.target.dataset.invstKey))}>{k_item}</div>)}</div></div>)}
        
   
        return(
        <div>
            <TopNav/>{cur_bok_feature.feature? wiki_tools2():null}
            <main className="sci-main-container" style={{backgroundColor:'white'}}>{cur_bok_feature.feature? wiki_tools(cur_bok.invst_result):null}
                <div ref={canvas_ref} className='sci-container'>
                {progress?<div style={{width:'70%', margin:'auto', padding:'2%'}}><PogressBarLinear /></div>:null}
               {/*  {progress?<div style={{width:'30%', margin:'auto', padding:'2%'}}> {ProgSpinner()}</div>:null} */}
                
                {cur_bok.wiki_query.bok_name?<div id='bok_subject'>
                    <div> Result of Wiki invetigation of:&nbsp;
                      <span style={{color:'rgb(191, 196, 21)' , fontSize:"1.3rem", fontWeight: "bold"}}>
                    {capitalize(cur_bok.wiki_query.bok_name)}{cur_bok.wiki_query.query?
                    <span style={{color:'red'}}>/{cur_bok.wiki_query.query}</span>:null}</span></div>
                </div>:null}
                {cur_bok_feature.feature== 'WIKIB'? cur_bok.invst_result[cur_bok_invst_mode]?<WikiBokSum cur_subject={cur_bok.wiki_query} cur_items={cur_bok.invst_result[cur_bok_invst_mode]}/>:null:null}

             {cur_bok_feature.feature== 'P_TREE'?<TreeGraph width={canvas_ref.current.offsetWidth} height={1100} data={cur_bok.graphs.P_TREE} />:null}
             {cur_bok_feature.feature== 'LP_TREE'?<TreeGraph width={canvas_ref.current.offsetWidth} height={1100} data={cur_bok.graphs.LP_TREE} />:null}
             {cur_bok_feature.feature== 'NIC_TREE'?<TreeGraph width={canvas_ref.current.offsetWidth} height={1100} data={cur_bok.graphs.NIC_TREE} />:null}
               {/*  {current_bok_feature.feature== 'maps'? <SingleBokGraphs head_query={current_bok.doc_url}/>:''}
                {current_bok_feature.feature== 'converse'? <NewsConverse head_query={current_bok.doc_url}/>:''} */}
              </div>
              { cur_bok.HC? <RightNav HC={cur_bok.HC} head_query={cur_bok.wiki_query.bok_name} />:''}
              
            </main>              
        </div>   
    )
}