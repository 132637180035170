
import React from 'react'

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { capitalize, TrimMyString, CheckDateValidity, Highlighted} from  '../utils/newsUtilFuncs'
import ReactTimeAgo from 'react-time-ago'
//const date="Fri, 11 Dec 2020 17:00:00 -0500"
import TimeAgo from 'timeago-react'

export const NewsItemCards = (props) =>{
    //const dispatch= useDispatch()
    //console.log('CURRENT NEWS from store',props.news)
    //const highlight=props.head_query
    //console.log('HIGHLIGHT TEST IS:', highlight)
   
    return(<div>
                <div className="news-container"style={{}}>
                <div id="news-columns">
                {props.news.news_items? props.news.news_items.map(item => 
                    <figure><a href= { item[1][0].meta.link } target="blank" id="news_link">
                        <div className="">
                                <div className="card-image">
                                    <img src= { item[1][0].meta.image } alt="" />
                                </div><figcaption>{ item[1][0].meta.title }</figcaption>
                                <div id="news_card_content">
                                 <div> {!isNaN(Date.parse(item[1][0].meta.date))? <span title={item[1][0].meta.date} style={{fontSize:"11px"}}>
                                       <ReactTimeAgo date={item[1][0].meta.date} locale="en-US" timeStyle="twitter"/>:</span>:null}
                                       
                                    <span className="sci-btn" style={{ fontSize:"11px", fontWeight:'bold', fontStyle:'italic'}} >{ capitalize(item[0][0])}</span>
                                    </div> 
                                                                                                     
                                    <p style={{fontSize:"13px", color:'#929496'}}><Highlighted text={ TrimMyString(item[1][0].sc4_par.replace(/[^\w\s]/gi, ''), 180)} highlight={props.head_query.query}/></p>
                                </div>
                        </div>
                        </a>
                    </figure>):''}
                </div>
                </div>
    </div>
              )
            }
 
