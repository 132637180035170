
import React, { useState,  useEffect, useRef } from 'react'


import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { capitalize, TrimMyString} from  '../news/utils/newsUtilFuncs'
//import {sbServices, setCurrentBok, setExploredBoks,setCurrentBokFeature} from './singleBokSlice'
import {GrGraphQl} from'react-icons/gr'
//import './static/css/singleBok_Kd.css'
import '../web_bok/static/css/webBok_Kd.css'
//import {ProgSpinner} from '../master/components/spinner_progress'
import {PogressBarLinear} from '../master/components/linearProgressbar'

import {TopNav } from '../news/layout_components/topNav'
import { LeftNav } from '../news/layout_components/leftNav'

import {RightNav} from '../news/layout_components/rightNav'
import {SbRightNav} from './components/sbRightNav'

import {NewsItemCards} from '../news/components/newsCards'
import { NewsHeadsTop } from '../news/components/newsHeadsAtTop'
import {NewsConverse} from '../news/components/newsConverse'

import {SingleBokSum} from './components/single_bok_sum'
import {SingleBokGraphs} from './components/single_bok_graphs'
import {FileUpload} from './components/fileUpload'
import {PaperDropzone} from './components/fileUpload_new'

import {current_doc_bok_feature,current_doc_bok_mode, current_doc_bok, setExploredDocBoks, setCurrentDocBokFeature,setCurrentDocBokMode, sbServices } from './singleBokSlice'

import{
     setFetching,isFetching
  }from '../master/masterSlice'
import { TreeGraph } from '../master/components/graphComponents/newsTreeMap2';
  
export const SingleBOK = () =>{
    const dispatch= useDispatch()
    const progress=useSelector(isFetching)
    //const cur_bok= useSelector(state =>state.singleBok.current_bok,  shallowEqual)
    const cur_bok =useSelector(current_doc_bok)
    console.log('Cuurent Single BOK  is:', cur_bok)
    const cur_bok_feature=useSelector(current_doc_bok_feature)
    //console.log('Cuurent single bok feature is:', cur_bok_feature)
    const cur_bok_invst_mode=useSelector(current_doc_bok_mode)
    console.log('Cuurent Single BOK res is:', cur_bok.res.content.sum_res[cur_bok_invst_mode])
    const canvas_ref=useRef()
    

    const active_mode_btn = (invst_mode) =>{
      if(document.querySelectorAll('.web_btn')){document.querySelectorAll('.web_btn').forEach(item =>{ 
        if (item.dataset.invstKey === invst_mode){item.style.color='rgb(127, 211, 1)'} else{item.style.color='whitesmoke'}}
      )}
      window.scrollTo({top: 0, behavior: 'smooth'})}

      useEffect(() => {    active_mode_btn(cur_bok_invst_mode) });
      

    const fetchSbDoc = (doc_data) =>{
        console.log(doc_data)
        dispatch(setFetching())
        sbServices.SbConverseRes(doc_data).then(resp =>{
           console.log('Got resultfrom back for single doc', resp);dispatch(setExploredDocBoks({'doc_url':resp.url, 'res':resp.res}))
           dispatch(setFetching())
      })}

    const handelSbEnter =(e)=>{
        let code = (e.keyCode ? e.keyCode : e.which);
      if(code === 13) {const query =cur_bok.doc_url+'&'+ e.target.value;
      fetchSbDoc(query); document.getElementById('converseBar').value=''
      }
    }
    const handelSbEnter2 =(e)=> {console.log('single doc kd input was:', e.target.valu)}

    const sb_tools2 =() =>{
        return(<div className='sci-container' style={{backgroundColor:'rgb(33, 32, 32)'}}><div className='' style={{width:'80%', margin:'auto'}}>
        <div className='sci-converse-head' ><span style={{margin:'2px'}}>  Maps:</span> 
        <div className='sci-converse-client-input-container' style={{width:'50%'}}>
        <div className='sci-converse-client-input' >
      <input id="converseBar" name="" type="search" required placeholder="find out news relations" onKeyUp = { e => handelSbEnter2(e)}/>
      </div><div><GrGraphQl  size='32px' style={{ marginLeft:5, marginTop:'2px',color: "rgb(228, 226, 100)" }}/></div>
      </div>
      <div class="" style={{margin:'5px 2rem', cursor:"pointer"}} data-head={''} onClick={e=> dispatch(setCurrentDocBokFeature({feature:'BAR', mode:''})) }>
        {cur_bok_feature.feature === 'BAR'?<span style={{ color:'#d9df13', textDecoration:"underline"}}> Bar Graphs</span>:<span>Bar Graphs</span>}</div>

      <div class="" style={{margin:'5px 2rem', cursor:"pointer"}} data-head={''} onClick={e=> dispatch(setCurrentDocBokFeature({feature:'TREE', mode:''}))}>
        {cur_bok_feature.feature === 'TREE'?<span style={{ color:'#d9df13', textDecoration:"underline"}}>Tree Graphs</span>: <span> Tree Graphs</span>}</div>
        <div class="" style={{margin:'5px 2rem', cursor:"pointer"}} data-head={''} onClick={e=> dispatch(setCurrentDocBokFeature({feature:'SB', mode:''}))}>
        {cur_bok_feature.feature === 'SB'?<span style={{ color:'#d9df13', textDecoration:"underline"}}>Single Bok Summary</span>: <span> Single Bok Summary</span>}</div>
        
      </div> </div>
      </div>)}

const sb_tools = (cur_bok_res) =>{ 
  return(<div id="left-nav" style={{backgroundColor:'whitesmoke'}}><div id="news_heads" style={{backgroundColor:'whitesmoke', padding: "1rem 5% 10vh 7%"}}>
{Object.keys(cur_bok_res.content.sum_res).map(k_item =><div class="web_btn" id={k_item} data-invst-key={k_item} onClick={e =>dispatch(setCurrentDocBokMode(e.target.dataset.invstKey))}>{k_item}</div>)}</div></div>)}
 
        return(
        <div>
            <TopNav/>{cur_bok_feature.feature? sb_tools2():null}
            <main className="sci-main-container" style={{backgroundColor:'white'}}>{cur_bok_feature.feature? sb_tools(cur_bok.res):null}
            
              <div ref={canvas_ref} className='sci-container'>
                {cur_bok.doc_url?<div style={{textAlign:"left", backgroundColor:"#212020"}}>
                  <a href={cur_bok.doc_url} style={{color:"rgb(191, 196, 21)", fontSize:'1.05rem', fontStyle:"italic", fontWeight:"bold"}}><span style={{color:"whitesmoke"}}> The Page:</span>  {cur_bok.doc_url}</a>
                  </div>:null}
              {progress?<div style={{width:'70%', margin:'auto', padding:'2%'}}><PogressBarLinear /></div>:null}
              {/*  {progress?<div style={{width:'30%', margin:'auto', padding:'2%'}}> {ProgSpinner()}</div>:null}
                 */}
                <FileUpload />
             {cur_bok_feature.feature== 'TREE'?< SingleBokGraphs tree_graph={cur_bok.res.graphs.TREE} canvas_width={canvas_ref.current.offsetWidth} />:null}
                {cur_bok_feature.feature== 'SB'? cur_bok.res.content.sum_res[cur_bok_invst_mode]?<SingleBokSum cur_items={cur_bok.res.content.sum_res[cur_bok_invst_mode]}/>:null:null}

               {/*  
                {cur_bok_feature.feature== 'converse'? <NewsConverse head_query={current_bok.doc_url}/>:''} */}
              </div>
              { cur_bok.res.HC? <SbRightNav HC={cur_bok.res.HC} head_query={cur_bok.doc_url} handelSbConverse={handelSbEnter}/>:null}
            </main>              
        </div>   
    )
}