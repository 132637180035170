
import React, { useState, useEffect, useRef } from 'react'
//import styled from 'styled-components';
const controlStyles = { fontSize: 18, display:'flex', width:'', padding:'5px' };
/* const selectStyles =styled.select`
  background-color: rgba(255,255,255,0.9);
  width: auto,
  padding: 5px;
  border: 1px solid #f2f2f2;
  border-radius: 2px;
  height: 2rem;`
 */
type Props = {
  layout: string;
  orientation: string;
  linkType: string;
  stepPercent: number;
  setLayout: (layout: string) => void;
  setOrientation: (orientation: string) => void;
  setLinkType: (linkType: string) => void;
  setStepPercent: (percent: number) => void;
};

export default function LinkControls({
  layout,
  orientation,
  linkType,
  stepPercent,
  setLayout,
  setOrientation,
  setLinkType,
  setStepPercent,
}: Props) {
  const [tree_graph_mode, setTreeGraphMode]= useState('TREE_CONS_MAP')
  return (
    <div style={controlStyles}>
      <div style={{display:"flex", flexDirection:"row"}}>
      <label>layout:</label>&nbsp;
      <select className="map_select" 
        onClick={e => e.stopPropagation()}
        onChange={e => {setLayout(e.target.value);
        if(e.target.value==='cartesian'){setLinkType('diagonal')}; if(e.target.value==='polar') {setLinkType('step')}}}
        value={layout}
      >
        <option value="cartesian">cartesian</option>
        <option value="polar">polar</option>
      </select>
      &nbsp;&nbsp;
      <label>orientation:</label>&nbsp;
      <select className="map_select" 
        onClick={e => e.stopPropagation()}
        onChange={e => setOrientation(e.target.value)}
        value={orientation}
        disabled={layout === 'polar'}
      >
        <option value="vertical">vertical</option>
        <option value="horizontal">horizontal</option>
      </select>
      &nbsp;&nbsp;
      <label>link:</label>&nbsp;
      <select className="map_select" 
        onClick={e => e.stopPropagation()}
        onChange={e => setLinkType(e.target.value)}
        value={linkType}
      >
        <option value="diagonal" >diagonal</option>
        <option value="step">step</option>
        <option value="curve">curve</option>
        <option value="line">line</option>
      </select></div>
      <div style={{display:"flex", position:'absolute', right:'20%' ,marginLeft:''}}>
      
      <div className="main_news_head" style={{margin:'0 2rem', cursor:"pointer"}} data-head={''} onClick={e=>setTreeGraphMode('TREE_CONS_MAP')}>
    {tree_graph_mode === 'TREE_CONS_MAP'?<span style={{ color:'', textDecoration:"underline"}}> CONS MAPS</span>:<span>CONS MAPS</span>}</div>
  
    <div className="main_news_head" style={{margin:'0 2rem', cursor:"pointer"}} data-head={''} onClick={e=> setTreeGraphMode('TREE_NOV_MAP') }>
    {tree_graph_mode === 'TREE_NOV_MAP'?<span style={{ color:'', textDecoration:"underline"}}> NOV MAPS</span>:<span>NOV MAPS</span>}</div>
     
      </div>
      {linkType === 'step' && layout !== 'polar' && (
        <>
          &nbsp;&nbsp;
          <label>step:</label>&nbsp;
          <div className="browser-default">
          <input id='map_range_input' style={{border:'none', margin:'0 0 -5px'}}
            onClick={e => e.stopPropagation()}
            type="range"
            min={0}
            max={1}
            step={0.1}
            onChange={e => setStepPercent(Number(e.target.value))}
            value={stepPercent}
            disabled={linkType !== 'step' || layout == 'polar'}
          /></div>
        </>
      )}
    </div>
  );
}
