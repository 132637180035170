
import React from 'react'

import { useSelector, useDispatch, shallowEqual } from "react-redux";
//import * as FaIcons from 'react-icons/fa';
//import * as AiIcons from 'react-icons/ai';
import  {
    newsServices,
    setCurrentNews,
    setExploredNews,
    searched_keys,
    
    } from '../newsSlice'

    import{
      setFetching,
    }from '../../master/masterSlice'


const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }


export const NewsHeads = (props) =>{

    const dispatch= useDispatch()
    const current_keys = useSelector(state=>state.news.query_list, shallowEqual)
    //console.log('*********************CURRENT KEYS**',current_keys)
    //console.log('*********************PROPS**',props)
    
    const handleSearchMain = (e) =>{

        let bok_name= document.getElementById('bok_name').textContent.trim().split(' ').join('-')
        let bok_query = e.target.dataset.head.trim().split(' ').join('-')
      
        let query= bok_name+'&'+bok_query
        if(current_keys.includes(query)){ 
            dispatch(setCurrentNews(query))
        return
        }
        dispatch(setFetching())
        console.log('query valueeeee', query)
        newsServices.searchNews(query).then(res =>
        {let payload={}; payload[query]=res; dispatch(setExploredNews({key:query, data:payload}))
      dispatch(setFetching())})
    }
const display_head_item = (item) =>{
  const hc =item.replace(/[^\w\.\s]/gi, '')
  //console.log(hc)
if(hc){return (<div class="sci-btn news_head_btn" data-head={item} onClick={e=>handleSearchMain(e)} >{hc}</div>)
}}

    return(
        <div>
        <div style={{backgroundColor:"", padding:"5%", color:"whitesmoke", position:"sticky", fontWeight:"bold", fontSize: ""}}>
          <p>INFO NUGGETS FOR:&nbsp;<span id="bok_name" className="main_news_head" data-head={''} onClick={e=>handleSearchMain(e)}>{capitalize(props.head_query.bok_name)}</span>
            <span id ='bok_query' className="main_news_head" >{props.head_query.bok_name != props.head_query.query?'/'+capitalize(props.head_query.query):''} </span> </p>
    </div>
            <div className="" id="news_heads">
              {props.HC? props.HC.map(item =>display_head_item(item)):null}
            </div>
    </div>

    )

}
