

import React, {useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import {sbServices} from '../singleBokSlice'

import '../../news/static/css/news_item.css'
import '../../news/static/css/search_news.css'
import '../../news/static/css/news_columns.css'

import {ProgSpinner} from '../../master/components/spinner_progress'
import {TopNav } from '../../news/layout_components/topNav'

import {GraphBar} from '../../master/components/graphComponents/graphBar'

import {TreeGraph} from '../../master/components/graphComponents/newsTreeMap2'

export const SingleBokGraphs = (props) =>{
    console.log('SB graph prop is', props)
    //const dispatch= useDispatch()
    //const [offset, setOffset]=useState()
    
      
        return(<TreeGraph width={props.canvas_width} height={1100} data={props.tree_graph} />
           
  ) 
}