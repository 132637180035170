

import React, { useState } from 'react'
import  {newsServices,} from '../newsSlice'
import { NewsHeadsTop } from './newsHeadsAtTop'
import {GrChat} from'react-icons/gr'
import {graph_spinner, linear_progress} from '../utils/spinner_progress'
import '../static/css/converse.css'

export const NewsConverse = (props) => {
    const [converse, setconverse] = useState([{q:'Hello', a:[{meta:{link:''}, sc3_sen:' Oh hi, how are you today?', sc4_par:' Oh hi, how are you today?'}]}])
    console.log(' conversation:', converse)
    const [progress, setProgress] = useState(false);

    const handelConverseEnter =(e)=>{
        let code = (e.keyCode ? e.keyCode : e.which);
      if(code == 13) {const query = e.target.value; 
        setProgress(true) 
      newsServices.converseNews('Global-State&'+query).then(res => {console.log(res);
        setconverse([{q:query, a:res}, ...converse ])
        setProgress(false)
      })
     document.getElementById('converseBar').value=''}
      }

      const render_converse =(converse) =>{
          return(<div className="news-container">
              {converse.map((item, idx)=><div>
                <div id='sci-converse-client-message' key={idx}><p>{item.q}</p></div>
                <br/>
                <div id='sci-converse-response' >{item.a.map((res, idx)=><div key={idx}> 
                <div id='sci-converse-response-item'>{item.a.length>1? <span >{idx+1}:&nbsp;</span>:null}
                {/* <p> {res.sc3_sen}</p> */}
                <p style={{color:"#051657", fontSize:'1.3rem', border:'1px  white', padding:'5px 15px'}}>
                  {res.sc4_par.length<181?res.sc4_par:res.sc3_sen}{res.meta.link? <a href={res.meta.link} style={{color:"darkgreen"}} target="_blank">&nbsp; Source</a>:null}
                </p>
                <br/>
                </div> </div>)}
                
                </div></div>
              )}
              </div>
          )
      }
const converseHead = ()=>{
  return(
    <div className=''>
        <div className='sci-converse-head'><h6 style={{margin:'2px'}}> News Inqueries:</h6> 
        <div className='sci-converse-client-input-container'>
        <div className='sci-converse-client-input'>
      <input id="converseBar" name="" type="search" required placeholder="talk about news" onKeyUp = { e => handelConverseEnter(e)}/>
      </div>
      <div><GrChat  size='32px' style={{ marginLeft:'5px', marginTop:'1px',color: "#838b7d" }}/></div>
      </div></div>
      
      </div>
  )
}

  return(
  <div className="sci-container"><NewsHeadsTop head_query={props.head_query} converse_head={converseHead} />
      {progress?<div style={{width:'30%', margin:'auto', padding:'2%'}}> {graph_spinner()}</div>:null}
      {render_converse(converse)} 

  </div>
    
    
)

  
    }
