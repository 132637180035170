
import React from 'react'
import { useSelector, useDispatch, shallowEqual } from "react-redux";
//import * as FaIcons from 'react-icons/fa';
//import * as GiIcons from 'react-icons/gi';
import { TiNews } from 'react-icons/ti'
//import { GrSearch } from'react-icons/gr'
import { CgSearch} from 'react-icons/cg'
import {CgHome} from 'react-icons/cg'
import {FaHome, FaDochub, FaWikipediaW, FaUserFriends, FaBattleNet} from 'react-icons/fa'
import {GrDocument} from 'react-icons/gr'
import {BiNews} from 'react-icons/bi'
import {IoDocuments} from 'react-icons/io'
import {VscSortPrecedence} from 'react-icons/vsc'

import  {
  newsServices,
  setCurrentNews,
  setExploredNews,
  searched_keys,
  } from '../newsSlice'

import {sbServices,
  setCurrentDocBok,
  setExploredDocBoks,
  setCurrentDocBokFeature,
  explored_doc_boks,

} from '../../singleBok/singleBokSlice.js'

import {wikiServices,
  setCurrentWikiBok,
  setExploredWikiBoks,
  setCurrentWikiBokFeature,
  explored_wiki_boks,

} from '../../wiki_kd/wikiBokSlice.js'

import {psServices,
  setCurrentPsBok,
  setExploredPsBoks,
  setCurrentPsFeature,
  explored_ps_boks,

} from '../../predictiveStates/PsSlice.js'

import {webServices,
  setCurrentWebBok,
  setExploredWebBoks,
  setCurrentWebBokFeature,
  explored_web_boks,

} from '../../web_bok/webBokSlice.js'

import{
  setCurrentFeature,currentFeature, setFetching, isFetching, setLogedIn, isLogedIn,
}from '../../master/masterSlice'

//import '../static/css/news_item.css'
import '../static/css/search_news.css'
//import 'materialize-css/dist/css/materialize.min.css';
import '../static/css/tooltip.css'
import {axiosInstance} from '../../../axiosApi'
import { Row } from 'react-bootstrap';

export const TopNavFeatureList =()=>{

  const dispatch= useDispatch()
  const islogedin= useSelector(isLogedIn)
  const isProgress = useSelector(isFetching)
  const current_keys = useSelector(state=>state.news.query_list, shallowEqual)
  const current_feature =useSelector(currentFeature)
  const doc_boks_explored=useSelector(explored_doc_boks)
  const wiki_boks_explored=useSelector(explored_wiki_boks)
  const ps_boks_explored =useSelector(explored_ps_boks)
  const web_boks_explored=useSelector(explored_web_boks)
  //console.log('WEB_BOKS_EXPLORED', web_boks_explored)
  
//------------------------------------
  const fetchNewsSearch =(query)=>{
    //console.log(query)
    if(current_keys.includes(query)){ dispatch(setCurrentNews(query));return}
    if(!isProgress){dispatch(setFetching())}
    newsServices.searchNews(query).then(res =>{
    console.log('Got result after search', res);
    let payload={}; payload[query]=res;
    dispatch(setExploredNews({key:query, data:payload}))
    dispatch(setFetching())
  })}

  const fetchDoc = (doc_data) =>{
    //console.log(doc_data)
    if(!isProgress){dispatch(setFetching())}
    sbServices.getSingleRes(doc_data, {timeout:35000}).then(resp =>{
    console.log('Got resultfrom back for single doc', resp);
    dispatch(setExploredDocBoks({doc_url:resp.url, res:resp.res}))
    dispatch(setFetching())

  }).catch(err=>{ console.log('fetch failed doing it again', err);
  setTimeout(()=>{sbServices.getSingleRes(doc_data, {timeout:10000}).then(resp =>{
    // console.log('Got result from back for web', resp);
    dispatch(setExploredDocBoks({doc_url:resp.url, res:resp.res}));
    dispatch(setFetching())})}, 40000)

})}

  const fetchWiki = (doc_data) =>{
    //console.log(doc_data)
    if(!isProgress){dispatch(setFetching())}
    wikiServices.getWikiRes(doc_data).then(resp =>{
  //  console.log('Got result from back for wiki', resp);
    dispatch(setExploredWikiBoks(resp))
    dispatch(setFetching())

      }).catch(err=>{ console.log('fetch failed doing it again', err);
        setTimeout(()=>{wikiServices.getWikiRes(doc_data, {timeout:20000}).then(resp =>{
        // console.log('Got result from back for web', resp);
        dispatch(setExploredWikiBoks(resp));
        dispatch(setFetching())})}, 240000)
  
})}
  const fetchPredictive = (doc_data) =>{
    //console.log(doc_data)
    if(!isProgress){dispatch(setFetching())}
    psServices.getPredictiveRes(doc_data).then(resp =>{
    // console.log('Got result from back for predictive news', resp);
    dispatch(setExploredPsBoks({'bok_name':resp.bok_name, 'res':resp.res}))
    dispatch(setFetching())

      }).catch(err=>{ console.log('fetch failed doing it again', err);
      setTimeout(()=>{psServices.getPredictiveRes(doc_data, {timeout:10000}).then(resp =>{
      // console.log('Got result from back for web', resp);
      dispatch(setExploredPsBoks({'bok_name':resp.bok_name, 'res':resp.res}));
      dispatch(setFetching())})}, 25000)

  })}
  const fetchWeb = (doc_data) =>{
    //console.log(doc_data)
    if(!isProgress){dispatch(setFetching())}
    //webServices.getWebRes(doc_data, {timeout:15000}).then(resp =>{
    webServices.getWebRes(doc_data).then(resp =>{
    console.log('Got result from back for web', resp);
    dispatch(setExploredWebBoks({'web_query':resp.subject, 'res':resp.res}))
    dispatch(setFetching())
  }).catch(err=>{ console.log('fetch failed doing it again', err);
    setTimeout(()=>{
      //webServices.getWebRes(doc_data, {timeout:20000}).then(resp =>{
        webServices.getWebRes(doc_data).then(resp =>{
    // console.log('Got result from back for web', resp);
    dispatch(setExploredWebBoks({'web_query':resp.subject, 'res':resp.res}))
    dispatch(setFetching())})}, 60000)
  })
}
//-----------------------------
  const handelNewsSearchEnter =(e)=>{
    let code = (e.keyCode ? e.keyCode : e.which);
  if(code === 13) {const query ='&'+ e.target.value; 
    fetchNewsSearch(query); document.getElementById('searchBar').value=''}
  }

  const handelNewsSearchIcon =(e)=>{  
    const query ='Global-State&'+ document.getElementById('searchBar').value; 
    fetchNewsSearch(query); document.getElementById('searchBar').value=''}

    const handelDocUrlEnter =(e)=>{
      let code = (e.keyCode ? e.keyCode : e.which);
    if(code === 13) {const url =e.target.value;
    if(doc_boks_explored.docs_urls.includes()){dispatch(setCurrentDocBok({'doc_url':url, 'res':doc_boks_explored.results[doc_boks_explored.docs_urls.filter(url=>url.id)]}));return}
    const doc_data={'bok_name':url, 'doc_url':url} 
      fetchDoc(doc_data); document.getElementById('urlBar').value=''}
    }

    const handelWikiSearchEnter =(e)=>{
      let code = (e.keyCode ? e.keyCode : e.which);
    if(code === 13) {const subject_key =e.target.value;
    if(wiki_boks_explored.subjects.includes()){dispatch(setCurrentWikiBok({'subject':subject_key, 'res':wiki_boks_explored.results[wiki_boks_explored.subjects.filter(subject=>subject.id)]}));return}
    const wiki_fetch_data={'bok_name':subject_key, 'query':''} 
      fetchWiki(wiki_fetch_data); document.getElementById('wikiBar').value=''}
    }
    const handelPredictiveSearchEnter =(e)=>{let code = (e.keyCode ? e.keyCode : e.which);
      if(code === 13) {const subject_key =e.target.value;
      if(ps_boks_explored.bok_names.includes()){dispatch(setCurrentPsBok({'bok_name':subject_key, 'res':ps_boks_explored.results[ps_boks_explored.bok_names.filter(subject=>subject.id)]}));return}
      const ps_fetch_data={'bok_name':subject_key, 'query':subject_key} 
        fetchPredictive(ps_fetch_data); document.getElementById('predictiveBar').value=''}
    }

   const handelWebSearchEnter =(e)=>{
      let code = (e.keyCode ? e.keyCode : e.which);
    if(code === 13) {const subject_key =e.target.value;
    if(web_boks_explored.subjects.includes(subject_key)){dispatch(setCurrentWebBok({'subject':subject_key, 'res':web_boks_explored.results[web_boks_explored.subjects.filter(subject=>subject.id)]}));return}
    const web_fetch_data={'bok_subject':subject_key, 'query':'', 'BOK_RANGE':1, 'NEW_SESSION':false} 
      fetchWeb(web_fetch_data); document.getElementById('webBar').value=''}
    } 

//--------------------
const HandleSignOut = async(e) =>{
    
  try {
    const response = await axiosInstance.post('api/accounts/blacklist/', {
      "refresh_token": localStorage.getItem("refresh_token")
    });
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('user');
    axiosInstance.defaults.headers['Authorization'] = null;
    // window.location.reload();
    dispatch(setCurrentFeature('news'))
    return response;
  }
  catch (e) {
    console.log(e);
  }
};	

const handleOptionChange =() =>{console.log('session option cheched')}

//--------------------

  const news_search_bar= ()=>{
    return( <div className="input_field_2" style={{ maxWidth: "700pt",height:40, minWidth: "25vw", color:"whitesmoke", display: "flex", marginRight: "2vw" }}>
      <input contentEditable='true' id="searchBar" className='tooltip' name="" type="search" spellcheck="true" required placeholder="search news" onKeyUp = { e => handelNewsSearchEnter(e)}/>
                <TiNews  size='32px' style={{ marginLeft:'5px', marginTop:'10px',color: '' }}  />
                <CgSearch  size='25px' style={{ marginLeft:'-20px', marginTop:'15px', color: "", cursor:"pointer"}} onClick= { e => handelNewsSearchIcon(e)} />
                </div>)}

const doc_url_input= ()=>{
  return(<div className="input_field_2" style={{ maxWidth: "700pt",height:40, minWidth: "25vw",color:"whitesmoke", display: "flex", marginRight: "2vw" }}>
    <input id="urlBar" name="doc_url" type="text" required placeholder="enter document url here" spellcheck="true" onKeyUp = { e => handelDocUrlEnter(e)}/></div>)}

const wiki_search_input= ()=>{
  return(<div className="input_field_2" style={{ maxWidth: "700pt",height:40, minWidth: "25vw",color:"whitesmoke", display: "flex", marginRight: "2vw" }}>
    <input id="wikiBar" name="wiki_query" type="text" required placeholder="Search In Wikipedia" spellcheck="true" onKeyUp = { e =>handelWikiSearchEnter(e)}/></div>)}

const predictive_search_input= ()=>{
  return(<div className="input_field_2" style={{ maxWidth: "700pt",height:40, minWidth: "25vw",color:"whitesmoke", display: "flex", marginRight: "2vw" }}>
    <input id="predictiveBar" name="predictive_query" type="text" required placeholder="Predictive Search" spellcheck="true" onKeyUp = { e =>handelPredictiveSearchEnter(e)}/></div>)}
 
const web_search_input= ()=>{
  return(<div className="input-field_2" style={{ maxWidth: "900pt",height:40, minWidth: "25vw", color:"whitesmoke", display: "flex", marginRight: "2vw" }}>
    <input id="webBar" name="web_query" type="text" required placeholder="Search In The Web" spellcheck="true" onKeyUp = { e =>handelWebSearchEnter(e)}/></div>)}
       
const web_search_input_with_bok_range= ()=>{
  return(<div className="input-field_3" style={{ maxWidth: "900pt",height:40, minWidth: "30vw", marginLeft:-150, color:"whitesmoke", display: "flex", marginRight: "2vw" }}>
    <input id="webBar" name="web_query" type="text" style={{ maxWidth: "25vw",}}required placeholder="Search In The Web" spellcheck="true" onKeyUp = { e =>handelWebSearchEnter(e)}/>
    <div style={{display:'flex', flexDirection:'row'}}>
      <label for="volume">EXTENT</label>
  <input type="range"  name="volume" id='bok-range' style={{width:100, borderStyle:'none'}} min="1" step='1' max="3"  value='1'/>
  
  <label>
    <input type="radio" name="SESSION" value="NEW SESSION"  onClick={handleOptionChange}/> NEW_SESSION</label>
    </div>
    </div>)} 


    return(
<div>
<div className="container2" style={{maxWidth:'1400', marginLeft:'25vw'}}>
      <div className="nav-wrapper2 " style={{height:54}}>
        <ul className="left hide-on-med-and-down" >
            <li>
                
                {current_feature !=='news'?current_feature ==='single_bok'?doc_url_input():
                current_feature ==='wiki_bok'?wiki_search_input():current_feature ==='web_bok'?web_search_input_with_bok_range():current_feature ==='predictive_states'?predictive_search_input():'':news_search_bar()}
               
            </li>
            <li style={{color: "rgb(110, 160, 9)"}}>
            <div className='top-nav-btn tooltip'style={{width:''}} onClick={e=>window.location = "/" }>
              <FaHome style={{display:"block", margin:'auto'}} size='25px'/>
            <span class="tooltiptext">Home</span>
              </div>
              </li>
              <li >
                <div className='top-nav-btn tooltip'style={{width:''}} onClick={e=> dispatch( setCurrentFeature('news'))} >
                  <BiNews style={{display:"block", margin:'auto'}} size='25px'/><span class="tooltiptext">Main News</span>
                  </div>
                </li>
                <li>
                  <div className='top-nav-btn tooltip' style={{width:'2.2vw'}} onClick={e=> dispatch( setCurrentFeature('single_bok'))} >
                  <FaDochub style={{display:"block", margin:'auto'}} size='25px'/><span style={{width:200}} class="tooltiptext">Your Doc Analysis</span></div>
                </li>
                <li>
                  <div className='top-nav-btn tooltip' style={{width:''}} onClick={e=> dispatch( setCurrentFeature('predictive_states'))} >
                  <VscSortPrecedence style={{display:"block", margin:'auto'}} size='25px'/><span style={{width:210}} class="tooltiptext">Predictive States</span></div>
                </li>
                <li>
                  <div className='top-nav-btn tooltip' style={{width:''}} onClick={e=> dispatch( setCurrentFeature('wiki_bok'))} >
                  <FaWikipediaW style={{display:"block", margin:'auto'}} size='25px'/><span style={{width:210}} class="tooltiptext">Wikipedia Discovery</span></div>
                </li>
                <li>
                  <div className='top-nav-btn tooltip' style={{width:''}} onClick={e=> dispatch( setCurrentFeature('web_bok'))} >
                  <FaBattleNet style={{display:"block", margin:'auto'}} size='25px'/><span style={{width:210}} class="tooltiptext">Web Discovery</span></div>
                </li>
                
                <li>
                  {islogedin?<div className='top-nav-btn tooltip' style={{width:''}} onClick={e=> {dispatch( setLogedIn()); HandleSignOut(e)}} >
                  <FaUserFriends style={{display:"block", margin:'auto'}} size='25px'/><span style={{width:210}} class="tooltiptext">Log Out From Your Account </span></div>:null}
                </li>
        </ul>
      </div>
      
      </div>
      </div>)
      }