
import React from 'react'

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { capitalize, TrimMyString} from  '../../news/utils/newsUtilFuncs'

import {psServices,
    setCurrentPsBok,
    setExploredPsBoks,
    setCurrentPsFeature,
    explored_ps_boks,
    current_ps_bok,
  
  } from '../../predictiveStates/PsSlice.js'
  import{
    setCurrentFeature,currentFeature, setFetching, isFetching, setLogedIn, isLogedIn,
  }from '../../master/masterSlice'
   
const topdivstyle ={backgroundColor:"", padding:"1px 10px",
 color:"whitesmoke",  fontWeight:"", fontSize: ".87rem", display:'flex',
 
}
export const PsTopNav = (props) =>{
    const dispatch= useDispatch()
    const next_states=props.cur_bok.res.content.next_states
    const ps_boks_explored =useSelector(explored_ps_boks)
    const ps_cur_bok_explored =useSelector(current_ps_bok)
    const isProgress = useSelector(isFetching)

    const fetchPredictive = (doc_data) =>{
        // console.log(doc_data)
        if(!isProgress){dispatch(setFetching())}
        psServices.getPredictiveRes(doc_data, {timeout:10000}).then(resp =>{
        //  console.log('Got result from back for predictive news', resp);
          dispatch(setExploredPsBoks({'bok_name':resp.bok_name, 'res':resp.res}))
          dispatch(setFetching())
    
          }).catch(err=>{ console.log('fetch failed doing it again', err);
          setTimeout(()=>{psServices.getPredictiveRes(doc_data, {timeout:10000}).then(resp =>{
          // console.log('Got result from back for web', resp);
          dispatch(setExploredPsBoks({'bok_name':resp.bok_name, 'res':resp.res}));
          dispatch(setFetching())})}, 25000)
    
      })}

      const handelPredictiveSearchEnter =(e)=>{const subject_key =e.target.dataset.head;
        // console.log(' pred naxt state was clicked:', subject_key)
        if(ps_boks_explored.bok_names.includes()){dispatch(setCurrentPsBok({'bok_name':subject_key, 'res':ps_boks_explored.results[ps_boks_explored.bok_names.filter(subject=>subject.id)]}));return}
        const ps_fetch_data={'bok_name':ps_cur_bok_explored.bok_name, 'query':subject_key} 
          fetchPredictive(ps_fetch_data); document.getElementById('predictiveBar').value=''}
      
    
    return(
        
            <div className="news-container" style={{position:"sticky", height:'auto'}}> 
            <h1 style={{fontSize:22, color:'whitesmoke', fontWeight:'bold', marginLeft:'30%', opacity:'.85'}}>{props.cur_bok.res.content.message}</h1>
            <div  style={topdivstyle}>
           <h2 className='sci-btn news_head_btn'>Head Bok is {capitalize(props.cur_bok.bok_name)}</h2>
               {next_states? next_states.map(item =>
               <div className='sci-btn news_head_btn' data-head={item} onClick={e=>handelPredictiveSearchEnter(e)}>{item}</div>):null}
            {/* <NewsHeadsTop  HC={next_states} head_query={''} last_updated={''} /> */}
        <div style={{color:"whitesmoke", target:'blank', display:'flex', flexDirection:'row'}}>
                      </div>
        </div> </div>  )}

/* 
export const PsTopNav = (props) =>{
    const dispatch= useDispatch()
    //console.log (props)

    const current_keys = useSelector(state=>state.news.query_list, shallowEqual)
    const current_news_feature = useSelector(state =>state.news.current_news_feature,  shallowEqual)
    const progress = useSelector(isFetching)

    const handleSearchMain = (e) =>{
        let heading_cat= document.getElementById('bok_name').textContent.trim().split(' ').join('-')
        let sub_head_cat = e.target.dataset.head.trim().split(' ').join('-')
        let query= heading_cat+'&'+sub_head_cat
        if(current_keys.includes(query)){  dispatch(setCurrentNews(query)); return }
        if(!progress){ dispatch(setFetching())}
        console.log('query valueeeee', query)
        newsServices.searchNews(query).then(res =>
        {let payload={}; payload[query]=res; dispatch(setExploredNews({key:query, data:payload}))
        dispatch(setFetching())})
    }
    const handelUpdate= () =>{if(!progress){ dispatch(setFetching())}
        newsServices.getNews('').then(news_data =>{console.log(news_data);
            let payload={}; payload['Global-State&']=news_data; dispatch(setExploredNews({key:'Global-State&', data:payload}));
            dispatch(setLastUpdatedTime(news_data.last_update))
            dispatch(setFetching())})
    }

    return(
        <div className="news-container" style={{position:"sticky", height:'auto'}}>
        {refresh_avaiable(props.last_updated, handelUpdate)}
        <div  style={topdivstyle}>
        
          <p><span id="bok_name" className="" style={{fontSize:18, fontWeight:'bold'}}  data-head={props.head_query.bok_name} onClick={e=>handleSearchMain(e)}>{capitalize(props.head_query.bok_name)}</span>
          <span id="bok_query" className="" >{props.head_query.bok_name != props.head_query.query?'/'+capitalize(props.head_query.query):''} </span></p>
          
          <div style={{width:'30%', margin:'', display:'flex',justifyContent:"center", alignItems:"" }}>
            <div style={{marginRight:'4%'}}></div>
          
          <div className=""  style={{marginRight:'3%', cursor:"pointer"}} data-head={''} onClick={e=>dispatch( setCurrentNewsFeature({feature:'news', mode:'top_related_news'}))} >
          {current_news_feature.mode == 'top_related_news'?<span style={{color:'', textDecoration:"underline"}}> Top Prdictive Related News</span>:<span> Top Predictive Related News</span>}
              </div>
          
              <div  className="" style={{marginRight:'3%', cursor:"pointer" }} data-head={''} onClick={e=>dispatch( setCurrentNewsFeature({feature:'maps', mode:'1'}))} >
              {current_news_feature.feature == 'maps'?<span style={{ color:'', textDecoration:"underline"}}>  Visual Explore</span>:<span> Visual Explore</span>}
        </div>
          <div className=""  style={{marginRight:'3%', cursor:"pointer"}} data-head={''} onClick={e=>dispatch( setCurrentNewsFeature({feature:'converse',mode:'1'}))} >
          {current_news_feature.feature == 'converse'?<span style={{ color:'', textDecoration:"underline"}}> Converse</span>:<span>Converse</span>}
              </div>
          </div>
          <div>
       {props.converse_head?props.converse_head():null}
                  {props.graph_search_head?props.graph_search_head():null}
                  </div>
      </div>
      <div style={{backgroundColor:"whitesmock" }}>
      {next_states? next_states.map(item =>
               <div className='sci-btn news_head_btn' data-head={item} onClick={e=>handleSearchMain(e)}>{item}</div>):null}
                  </div>
                  
    </div>

    )

}
 */