import axios from 'axios';
import {axiosInstance} from "../../../axiosApi";
import {API_URL_BASE} from "../../../url_settings";



const app_adress='/news/'
const ROOT_URL = window.location.protocol + "//" + window.location.host
console.log(' THIS IS ROOT_URL:', ROOT_URL)
//const API_URL=ROOT_URL.includes('localhost')?'http://192.168.0.12:9000':ROOT_URL
const API_URL=API_URL_BASE+app_adress

//API_URL = 'http://192.168.0.12:9000' is for dev and tests
//let API_URL = ROOT_URL  is fro production

console.log(' THIS IS API_URL:', API_URL)
export default class NewsService {
	getUsersExample() {
		return axios.get('https://jsonplaceholder.typicode.com/users/')
	};
	getNews(query) {
		const url = `${API_URL}` + query;
		return axiosInstance.get(url, {}).then(response => response.data);
	};
	searchNews(query) {
		if (!query) {query = ''}
		const url = `${API_URL}` + query;
		return axiosInstance.get(url).then(response => response.data);
	};
	converseNews(query) {
		const url = `${API_URL}converse/` + query;
		return axiosInstance.get(url, {
			//crossdomain: true
		}).then(response => response.data);
	}
	graphNews(query, timeout_option) {
		const url = `${API_URL}graphs/` + query;
		return axios.get(url , timeout_option).then(response => response.data)}
	// 	return axiosInstance.get(url, {
	// 		//crossdomain: true
	// 	}).then(response => response.data);
	// }

}