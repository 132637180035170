import React from 'react'
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import './static/css/news_item.css'
import './static/css/search_news.css'
import './static/css/news_columns.css'
//import {TopNav} from '../master/components/master_layout_components/topNav'
import {TopNav } from './layout_components/topNav'
import { LeftNav } from './layout_components/leftNav'
import {RightNav} from './layout_components/rightNav'
import {NewsItemCards} from './components/newsCards'
import {TopNewsCards} from './components/topNewsCards'
import { NewsHeadsTop } from './components/newsHeadsAtTop'
import  { newsServices,setExploredNews,setLastUpdatedTime,  }from './newsSlice';
import {NewsConverse} from './components/newsConverse'
import {NewsGraphs} from './components/newsGraphs'

import  {isFetching} from '../master/masterSlice';
import {ProgSpinner} from '../master/components/spinner_progress'

export const SciNews = () =>{
    const dispatch= useDispatch()
    
    const refresh_flag = useSelector(state =>state.news.refreshed,  shallowEqual)
    const current_news_feature = useSelector(state =>state.news.current_news_feature,  shallowEqual)
    const progress=useSelector(isFetching)

    // console.log('Cuurent news feature is:', current_news_feature)
    // console.log('Cuurent news mode is:', current_news_feature.mode)
    // console.log('Cuurent news Fetching status is:', progress)
    
    if (!refresh_flag){newsServices.getNews('').then(news_data =>{//console.log(news_data);
        let payload={}; payload['Global-State&']=news_data; dispatch(setExploredNews({key:'Global-State&', data:payload}))
        dispatch(setLastUpdatedTime(Math.floor(new Date().getTime()/1000)))
        })}
    const last_updated = useSelector(state =>state.news.last_updated_news_time,  shallowEqual)
    const current_news_key=useSelector(state=>state.news.current_news, shallowEqual)
    const news_head_query=useSelector(state=>state.news.explored_news[current_news_key].head.query, shallowEqual)
    const current_news = useSelector(state=>state.news.explored_news[current_news_key].news, shallowEqual)
    const current_news_explored = useSelector(state=>state.news.explored_news, shallowEqual)
    // console.log('current news explored are:', current_news_explored)
    //console.log('current news head_query  is:', news_head_query)
    
    const current_top_news = useSelector(state=>state.news.explored_news[current_news_key].labeled_news, shallowEqual)

    const render_news_content = ()=>{
        return( <div class="sci-container">
            {progress?<div style={{width:'30%', margin:'auto', padding:'2%'}}> {ProgSpinner()}</div>:null}
                    <NewsHeadsTop  HC={current_news.HC} head_query={news_head_query} last_updated={last_updated} />
                    {current_news_feature.mode== 'top_related_news'?<TopNewsCards top_news={current_top_news} head_query={news_head_query} />:null}
                    {current_news_feature.mode== 'cat_news'?<NewsItemCards news={current_news} head_query={news_head_query}/>:null}
                </div>)
    }
        return(
        <div>
            <TopNav/>
            <main className="sci-main-container">
                <LeftNav/>
                {current_news_feature.feature== 'news'? render_news_content():''}

                {current_news_feature.feature== 'maps'? <NewsGraphs progress={progress} head_query={news_head_query}/>:''}
                {current_news_feature.feature== 'converse'? <NewsConverse head_query={news_head_query}/>:''}
              { current_news.NIC_HC? <RightNav HC={current_news.NIC_HC} head_query={news_head_query} />:''}
            </main>              
        </div>   
    )
}