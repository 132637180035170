import axios from "axios";
import { API_URL_BASE} from "../../../url_settings";

const API_URL = API_URL_BASE


export default class AccountServices {
  register(data) {
    const prof_url = API_URL + "/api/accounts/user/create/";
    // console.log("REGISTER URL ====", prof_url);

    return axios.post(`${API_URL}/api/accounts/user/create/`, data);
  }
  resend_activation_email(data) {
    return axios.post(`${API_URL}/api/accounts/email/activate/`, data);
  }


}

