import React from 'react'
import { useSelector, useDispatch, shallowEqual } from "react-redux"

import {Modal, Button} from 'react-bootstrap'
// import "bootstrap/dist/css/bootstrap.css";



export const HandleRegisterationModal= (props) =>{

    // const user_data = useSelector(state => state.master.user_data)
    // console.log('I am in modal file', user_data)

    return (
      <Modal
        {...props}
        size = "sm"
        // aria-labelledby="contained-modal-title-vcenter"
        centered
        id='modal'
      >
          
        {/* <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            The activation 
          </Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
        <div style={{color:'blue'}} >
          <p>
          We have sent you an email, please confirm your email address to complete registration
          </p></div>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={props.onHide}>Close</button>
        </Modal.Footer> 
      </Modal>
    );
  }
