import React from 'react'

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { capitalize, TrimMyString} from  '../../news/utils/newsUtilFuncs'
//import '../../news/static/css/news_item.css'
//import '../../news/static/css/search_news.css'
import '../../web_bok/static/css/webBok_Kd.css'

export const WikiItemCards = (props) =>{
    const dispatch= useDispatch()
    //console.log('CURRENT wiki card input is',props.card_props)
    const item=props.card_props
   
    return(<div> 
                
        <figure><a href={item.address[0]} target="blank" id="">
                        <div className="">
                                {/* {item.meta.image?<div className="card-image">
                                 <img src= { item.meta.image } alt="" />
                                </div>:''} */}
                                <figcaption >Wiki Title:<span style={{color:'rgb(191, 196, 21)', fontSize:'1.2rem'}}> { item.address[1] }</span> </figcaption>
                                {item.content.sc3?
                                <figcaption style={{fontSize:'1.1rem',padding:15, color:'rgb(191, 196, 21)'}}>
                                    {TrimMyString(item.content.sc3.replace(/[^\w\s]/gi, ''), 320) }
                                    </figcaption>:
                                    null}
                                                                                                                                           
                                   <p style={{fontSize:"1.1rem",color:'whitesmoke', padding:15}}>
                                       { TrimMyString(item.content.sc4.replace(/[^\w\s]/gi, ''), 680)}
                                     </p>
                                </div>    </a>                    
                    </figure>
                    </div>
               
              )
            }
