import axios from 'axios';
import {axiosInstance} from "../../../axiosApi"
import {API_URL_BASE} from "../../../url_settings";

const ROOT_URL = window.location.protocol + "//" + window.location.host
console.log(' THIS IS ROOT_URL:', ROOT_URL)
const app_adress='/webBok/'
const API_URL=API_URL_BASE+ app_adress
console.log(' THIS IS API_URL:', API_URL)
//const csrftoken = document.querySelector('[name=csrfmiddlewaretoken]').value;
//console.log(' THIS IS CSRF TOCKEN:', csrftoken)

export default class WebBokService {
	getWebRes(doc_data, timeout_option) {
		const url = API_URL ;
		console.log('Im agoing to fetch this from web back', url)
		return axiosInstance.post(url, doc_data, timeout_option).then(response => response.data)
	};
	exploreWeb(query) {
		const url = `${API_URL}explore/` + query;
		return axiosInstance.get(url, {
			//crossdomain: true
		}).then(response => response.data);
	}
	converseWeb(query) {
		const url = `${API_URL}converse/` + query;
		return axiosInstance.get(url, {
			//crossdomain: true
		}).then(response => response.data);
	}
	graphWeb(query) {
		const url = `${API_URL}graphs/` + query;
		return axiosInstance.get(url, {
			//crossdomain: true
		}).then(response => response.data);
	}
	searchWeb(query) {
        if (!query) {query = ''}
        const url = `${API_URL}search/`+ query;
		return axios.get(url).then(response => response.data);
	};

}