import React from 'react';

import { MasterApp }  from './features/master/master_app'
const App =() =>{
  return(
    <div>
      <MasterApp/>
     </div>
  )
}

export default App;
