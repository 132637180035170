
import React, { } from 'react'

export const ProgressBar = ( props) => {
  const {bgcolor, completed} = props

const containerStyles = {
      height: 20,
      width: '100%',
      backgroundColor: "#e0e0de",
      borderRadius: 50,
      margin: 50
    }
  
    const fillerStyles = {
      height: '100%',
      width: `${completed}%`,
      backgroundColor: bgcolor,
      borderRadius: 'inherit',
      textAlign: 'right',
      transition: 'width 1s ease-in-out',
    }
    const progressBarStyles = {
        height: '100%',
        width:'100%' ,
        backgroundColor: bgcolor,
        borderRadius: 'inherit',
        textAlign: 'right',
        transition: 'width 1s ease-in-out',
      }
    const labelStyles = {
      padding: 5,
      color: 'white',
      fontWeight: 'bold'
    } 
    return (
      <div style={containerStyles}>
        <div id="Dyno-Bar" style={fillerStyles}>
          <span id="Dyno-Bar-Label" style={labelStyles}>{`${completed}%`}</span>
        </div>
      </div>
    );
  };