
import React from 'react'

import { capitalize, TrimMyString, CheckDateValidity, Highlighted} from  '../utils/newsUtilFuncs'
import ReactTimeAgo from 'react-time-ago'

const { GiRelicBlade } = require("react-icons/gi");

const date="Fri, 11 Dec 2020 17:00:00 -0500"

export const TopNewsCards = (props) =>{
       console.log('props inside newstopcard',props)
    return(<div>
                <div className="news-container"style={{}}>
                <div id="news-columns">
                {props.top_news? props.top_news.map(item => 
                    <figure><a href= { item.meta.link } target="blank" id="news_link">
                        <div className="">
                                <div className="card-image">
                                    <img src= { item.meta.image } alt="" />
                                </div><figcaption>{ item.meta.title }</figcaption>
                                <div id="news_card_content">
                                <div>
                                {!isNaN(Date.parse(item.meta.date))?<span title={item.meta.date} style={{fontSize:"11px", color:''}}>
                                   <ReactTimeAgo date={item.meta.date} locale="en-US"/>:</span>:null}
                                    
                                        {item.meta.keywords.map((key, idx)=> <span class="sci-btn" style={{fontSize:11, color:"",fontWeight:'bold', fontStyle:'italic' }} data-head={key} idx={idx} >
                                        { capitalize(key)}{item.meta.keywords.length>idx+1? <span style={{color:'#b9b8c8',}}>&nbsp;|</span>:null}</span>)}
                                        
                                    </div>                                                          
                                    <p style={{marginTop:7}}>{item.content.slice(0,5).map((par, idx)=><p style={{fontSize:"13px", color:'#929496'}}>
                                    <Highlighted text={ TrimMyString(par.replace(/[^\w\s]/gi, ''), 180)} highlight={props.head_query.query}/>
                                        </p>)}</p>
                                </div>
                        </div>
                        </a>
                    </figure>):null}
                </div>
                </div>
    </div>
              )
            }
 
