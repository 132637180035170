import React from 'react'
/* import { Route } from 'react-router-dom' */
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { NewsHeads } from '../components/newsHeads';


export const RightNav = (props) =>{
return(
<div id="right-nav">

    <NewsHeads HC={props.HC} head_query={props.head_query}/>
        
      </div>
  
  )}