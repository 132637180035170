import { createSlice } from '@reduxjs/toolkit';

import PredictiveStateServices from './utils/predictiveStateServices';

export const psServices = new PredictiveStateServices()

export const predictiveStateSlice = createSlice({
  name: 'predictive_state',
  initialState: {
    ps_fetching:false,
    refreshed:false,
    explored_ps_boks:{bok_names:['bok_name_lists'], results:[{bok_name:'', res:{}}]},
    current_ps_bok:{bok_name:'test', res:{HC:['khar 0',],graphs:{}, content:{message:'Predictive content', next_states:['sc item 1, sc item 2'], sum_res:null}}},
    query_list:[],
    current_ps_bok_feature:{feature:'PS', mode:'next_state'}

  },
  reducers: {
    
    setCurrentPsBok: (state, action) =>{
      state.current_ps_bok=action.payload
      state.current_ps_bok_feature={feature:'PS', mode:'next_state'}
    },
    setCurrentPsBokFeature: (state, action) =>{
      state.current_ps_bok_feature=action.payload
    },

    setExploredPsBoks: (state, action) =>{
        state.explored_ps_boks.bok_names.push(action.payload.bok_name)
        state.explored_ps_boks.results.push(action.payload.res)
        state.current_ps_bok=action.payload
        //state.refreshed=true;
        //state.query_list.push(action.payload.key);
        state.current_ps_bok_feature={feature:'PS', mode:'next_state'}
      },
    setPsFetching: (state)=> {state.ps_fetching= !state.ps_fetching},
    
  },
});


export const { 
    setCurrentPsBok,
    setPsFetching,
    setExploredPsBoks,
    setCurrentPsBokFeature,
    
  
  } = predictiveStateSlice.actions;

export const explored_ps_boks = state => state.predictiveState.explored_ps_boks; // predictiveState is set in teh store.js which combines all teh sattes and reduces Hamid Dec 20, 2020
export const current_ps_bok = state => state.predictiveState.current_ps_bok;
export const current_ps_bok_feature = state => state.predictiveState.current_ps_bok_feature;
export default predictiveStateSlice.reducer;