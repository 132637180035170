import React, { useState } from 'react'

import {Bar} from 'react-chartjs-2';

export const GraphBar = (props)=> {
    //console.log('graph props are', props.data)
    const displayName= props.data.type
    
    const data = {
        labels: props.data.assoc,
        datasets: [
          {
            label: 'First Highest Assoc.',
            backgroundColor: 'darkgrey',
            borderColor: 'darkblue',
            borderWidth: 1,
            hoverBackgroundColor: 'darkred',
            hoverBorderColor: 'rgba(255,99,132,1)',
            data: props.data.values,
          }
        ]
      };
    
      return (
        <div>
            <h1></h1>
        <div>
      <h2><span style={{color:'rgb(2, 2, 34)',fontWeight:"bold",borderBottom:'1px solid rgb(2, 2, 34)', fontSize:18}}> {displayName}</span></h2>
      <Bar
        data={data}
        width={100}
        height={250}
        options={{
          maintainAspectRatio: false
        }}
      />
    </div>
    
    </div>
  )}