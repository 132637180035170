

import React, { useState,  useEffect, useRef } from 'react'
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import  {newsServices,} from '../newsSlice'
import { NewsHeadsTop } from './newsHeadsAtTop'
//import {Maps} from '../../mapsAndCharts/components/sc1map'
//import {Example} from '../../mapsAndCharts/components/sc1maptooltip1'
//import CircularProgress from '@material-ui/core/CircularProgress';

import {GrGraphQl} from'react-icons/gr'
import {GraphBar} from '../../master/components/graphComponents/graphBar'
import {GlobeMap} from '../../master/components/graphComponents/globeMap'
//import {Example} from '../../master/components/graphComponents/newsTreeMapVisx'
import {TreeGraph} from '../../master/components/graphComponents/newsTreeMap2'
//import {BarGraphVx} from '../../master/components/graphComponents/graphBarVx'

import {ProgSpinner} from '../../master/components/spinner_progress'
import {setFetching} from '../../master/masterSlice'

export const NewsGraphs = (props) => {
  const dispatch= useDispatch()

  const ref = useRef(null);
  const graph_init_converse=[{q:'hello',a:[{meta:{link:''}, sc3_sen:' Oh hi, ready to explor?', sc4_par:' Oh hi, ready to explore news maps?'}]}]

    const [graphs, setGraphs] = useState({q:'',a:''})
    const [graph_mode, setGraphMode]= useState('BAR')
    //const [progress, setProgress] = useState(false);

    //console.log(' GRAPH ANSWER is:',graphs.a)
    const bar_graphs=graphs.a.res_data?graphs.a.res_data[graph_mode]:null
    if(graphs.a && !graphs.a.res_data  ){graph_init_converse[0].a[0].sc4_par=graphs.a.sc4_par}


    const handelGraphEnter =(e)=>{
        let code = (e.keyCode ? e.keyCode : e.which);
      if(code === 13) {const query ='Global-State&'+ e.target.value+`&${graph_mode}`;
      const waiting_time=90000
      dispatch(setFetching()) 
      newsServices.graphNews(query, waiting_time).then(res => {
        //console.log(res);
       /*  setGraphs([{q:query, a:res}, ...graphs] ) */
        setGraphs({q:query, a:res} )
        dispatch(setFetching())
      }).catch(err=>{ console.log('fetch failed doing it again', err);
      setTimeout(()=>{newsServices.graphNews(query).then(res =>{
      // console.log('Got result from back for web', resp);
      setGraphs({q:query, a:res} )
      dispatch(setFetching())})}, waiting_time)
    })
      //console.log(' new Graphs:', graphs)
     document.getElementById('converseBar').value='';
    }
      }
      
    const render_converse =(converse) =>{
      return(<div className="news-container">
          {converse.map((item, idx)=><div>
            {item.q?<div id='sci-converse-client-message' key={idx}><p>{item.q}</p></div>:null}
            <br/>
            <div id='sci-converse-response' >{item.a.map((res, idx)=><div key={idx}> 
            <div id='sci-converse-response-item'>{item.a.length>1? <span >{idx+1}:&nbsp;</span>:null}
            {/* <p> {res.sc3_sen}</p> */}
            <p style={{color:"#051657", fontSize:'1.3rem', border:'1px  white', padding:'5px 15px'}}>
              {res.sc4_par}{res.meta.link? <a href={res.meta.link} style={{color:"darkgreen"}} target="_blank" rel="noopener noreferrer">&nbsp; Source</a>:null}
            </p>
            <br/>
            </div> </div>)}

            </div></div>
          )}
          </div>
      )
  }

    
    const graphBar3 =() =>{
    return(<div className='' >
    <div className='sci-converse-head' ><span style={{margin:'2px'}}> News Maps:</span> 
    <div className='sci-converse-client-input-container' style={{width:'50%'}}>
    <div className='sci-converse-client-input' >
  <input id="converseBar" name="" type="search" required placeholder="find out news relations" onKeyUp = { e => handelGraphEnter(e)}/>
  </div><div><GrGraphQl  size='32px' style={{ marginLeft:5, marginTop:'2px',color: "rgb(228, 226, 100)" }}/></div>
  </div>
  <div class="" style={{margin:'5px 2rem', cursor:"pointer"}} data-head={''} onClick={e=> setGraphMode('BAR') }>
    {graph_mode === 'BAR'?<span style={{ color:'#d9df13', textDecoration:"underline"}}> Bar Graphs</span>:<span>Bar Graphs</span>}</div>
  <div class="" style={{margin:'5px 2rem', cursor:"pointer"}} data-head={''} onClick={e=> setGraphMode('TREE')}>
  {/* <span style={{ color:'', textDecoration:graph_mode == 'TREE'?"underline":''}}>Tree Graphs</span> */}
    {graph_mode === 'TREE'?<span style={{ color:'#d9df13', textDecoration:"underline"}}>Tree Graphs</span>: <span> Tree Graphs</span>}</div>
    <div class="" style={{margin:'5px 2rem', cursor:"pointer"}} data-head={''} onClick={e=> setGraphMode('GLOBEMAP') }>
    {graph_mode === 'GLOBEMAP'?<span style={{ color:'#d9df13', textDecoration:"underline"}}> Bar Graphs</span>:<span>Globe Maps</span>}</div>
  </div> 
  </div>)}
    
  return(
  
<div ref={ref}>
  <div className="sci-container" >  <NewsHeadsTop head_query={props.head_query} graph_search_head={graphBar3}/> 
  {props.progress?<div style={{width:'30%', margin:'auto', padding:'2%'}}> {ProgSpinner()}</div>:null}
  {graph_mode === 'TREE' && graphs.a?<TreeGraph width={ref.current.offsetWidth} height={1100} data={graphs.a.res_data[graph_mode]} />:null}</div>
    {graph_mode === 'GLOBEMAP'?<GlobeMap/>:null} 
    {graph_mode === 'BAR' && graphs.a?
      <div className="news-container"style={{}}>
        <div style={{width:'50%', margin:'auto', padding:10, borderBottom:'1px solid rgb(2, 2, 34)'}}>News Graphs for <span style={{color:"rgb(2, 2, 34)", fontWeight:"bold"}}>{graphs.q.split('&')[1]}</span>  </div>
        <div style={{margin:'1rem 2rem'}}>
          {Object.keys(graphs.a.res_data[graph_mode]).map(k =><GraphBar data={bar_graphs[k]}/>)} 
        </div>
      </div>:render_converse(graph_init_converse)}
  </div>
)}

