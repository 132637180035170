import React from 'react'
/* import { Route } from 'react-router-dom' */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee } from '@fortawesome/free-solid-svg-icons'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import {Bar} from 'react-chartjs-2';

const data = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  datasets: [
    {
      label: 'My First dataset',
      backgroundColor: 'rgba(255,99,132,0.2)',
      borderColor: 'rgba(255,99,132,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(255,99,132,0.4)',
      hoverBorderColor: 'rgba(255,99,132,1)',
      data: [65, 59, 80, 81, 56, 55, 40]
    }
  ]
};


export const Maps = ()=> {
  const displayName= 'BarExample'
  
    return (
      <div>
        {/* <div className='container' style={{backgroundColor:"rgb(0 0 0)", padding:"0%", color:"whitesmoke",  fontWeight:"bold", fontSize: "1.25rem"}}>
         <div className='container'>
            <div className="search-box" role="none" style={{display:"flex", flexDirection: "row"}} data-aura-rendered-by="139:0">
                <button className="slds-button slds-button_icon search-inputSearchButton search-inputSearchButton--left slds-button_icon-border" tabindex="-1" type="button" title="Search" data-aura-rendered-by="173:0">
                <lightning-primitive-icon data-aura-rendered-by="174:0" lightning-primitiveicon_primitiveicon-host="">
                <svg lightning-primitiveIcon_primitiveIcon="" focusable="false" data-key="search" aria-hidden="true" className="slds-button__icon">
              <use lightning-primitiveIcon_primitiveIcon="" xlinkhref="/_slds/icons/utility-sprite/svg/symbols.svg?cache=9.36.1#search"></use> 
                </svg></lightning-primitive-icon>
                <span className="slds-assistive-text" data-aura-rendered-by="176:0">Search</span>
                </button> 
                <label className="slds-assistive-text" for="131:0-input" data-aura-rendered-by="141:0">Search</label> 
                <FontAwesomeIcon icon={['fab', 'google']} />
                <FontAwesomeIcon icon="spinner" size="xs" />
                <FontAwesomeIcon icon={faSearch} />
                <input className="search-input search-input--left" aria-autocomplete="list" type="text" placeholder="Ask your question here" id="131:0-input" aria-activedescendant="" aria-controls="131:0-listbox" aria-label="Ask your question here" data-aura-rendered-by="143:0"/>
                  </div>
                  </div></div> */}

        
          <h1> The MAP feature</h1>
          <div>
        <h2>Bar Example (custom size)</h2>
        <Bar
          data={data}
          width={100}
          height={50}
          options={{
            maintainAspectRatio: false
          }}
        />
      </div>
      
      </div>
    )}
export default Maps

/* export default React.createclassName({
  displayName: 'BarExample',

  render() {
    return (
      <div>
        <h2>Bar Example (custom size)</h2>
        <Bar
          data={data}
          width={100}
          height={50}
          options={{
            maintainAspectRatio: false
          }}
        />
      </div>
    );
  }
}); */
