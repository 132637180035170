
import React from 'react'

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { capitalize, TrimMyString} from  '../../news/utils/newsUtilFuncs'
//import {current_bok} from '../singleBokSlice'

//import '../../news/static/css/news_item.css'
//import '../../news/static/css/search_news.css'
//import '../../news/static/css/news_columns.css'
//import '..//static/css/wikiBok_Kd.css'
import {TopNav } from '../../news/layout_components/topNav'
import {WikiItemCards} from './wiki_bok_cards'
import '../../web_bok/static/css/webBok_Kd.css'

export const WikiBokSum = (props) =>{
    const dispatch= useDispatch()
    //const cur_bok =useSelector(current_bok)
    console.log('CURRENT wikiboksum input is',props)
    
        return(
        <div>
         {/*    <TopNav/> */}
            <div className="web-container"style={{}}>
            <div id="web-columns">
                {props.cur_items[0].content.sc4?
                props.cur_items.map(item =><WikiItemCards card_props={item}/>):
                <h1> THERE IS NO WIKI RESULT AT THIS TIME</h1>}
            </div>
            
            </div>
        </div>
    )
}

/* {Object.keys(props.cur_bok.invst_result).map(res_key=>
                        <div id="news_card_title" ><h1> Result of {res_key}:</h1><br/>
                        <WikiItemCards card_props={props.cur_bok.invst_result[res_key]}/>
                        </div> 
                     )}       */