import React from 'react'
/* import { Route } from 'react-router-dom' */

import { useSelector, useDispatch, shallowEqual } from "react-redux";

import  {
    newsServices,
    setCurrentNews,
    setExploredNews,
    } from '../newsSlice'
import{
      setFetching,isFetching,
    }from '../../master/masterSlice'

const MAIN_HEAD_CATS= ['BUSINESS', 'SCIENCE', 'TECHNOLOGY', 'POLITICS', 'FINANCIAL','ENTERTAINMENT', 'SPORTS', 'HEALTH', 'NOVEL NEWS' ]

export const LeftNav = () =>{
  const dispatch = useDispatch()
  const isProgress = useSelector(isFetching)
  const current_keys = useSelector(state=>state.news.query_list, shallowEqual)

const fetchNewsSearch =(query)=>{
  //console.log('query value is ',query)
  if(current_keys.includes(query)){ dispatch(setCurrentNews(query));return}
  if(!isProgress){dispatch(setFetching())}
  newsServices.searchNews(query).then(res =>{
  //console.log('Got result aftet search', res);
  let payload={}; payload[query]=res;
  dispatch(setExploredNews({key:query, data:payload}))
  dispatch(setFetching())
})}

const handleSearchMain = (e) =>{
  let heading_cat= document.getElementById('bok_name').textContent.trim().split(' ').join('-')
  let sub_head_cat = e.target.dataset.head.trim().split(' ').join('-')
  let query= heading_cat+'&'+sub_head_cat
  fetchNewsSearch(query)
}
return(

<div id="left-nav">
  {/* <ul classNameName="sidenav" id="mobile-nav">
    <li>
      <input type="text"/>
    </li>
    <li>
      <a href="/">Home</a>
    </li>
    <li>
        <a href="/news">MAIN_NEWS</a>
      </li>
      <li>
        <a href="/globe/2">GLOBAL_NEWS</a>
      </li>
    <li>
      <a href="/search/">Search</a>
    </li>
    
  </ul> */}

        <div id="news_heads" style={{ padding: "0 5% 10vh 20%"}}>
        

        {MAIN_HEAD_CATS.map(item => <p><div class="sci-btn main_news_head" data-head={item} onClick={e=>handleSearchMain(e)} >{item}</div></p>)}
        
        </div>
 
  </div>
  )}