
import React from 'react'

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { capitalize, TrimMyString} from  '../../news/utils/newsUtilFuncs'
import {current_bok} from '../singleBokSlice'

import '../../news/static/css/news_item.css'
import '../../news/static/css/search_news.css'
//import '../../news/static/css/news_columns.css'
import '../../web_bok/static/css/webBok_Kd.css'
import {TopNav } from '../../news/layout_components/topNav'
import {SbItemCards} from'./single_bok_cards'


export const SingleBokSum = (props) =>{
    
    console.log('CURRENT single bok sum_res from store',props)
        return(<div className="web-container"> 
        <div id="web-columns">
            {props.cur_items.map(item =><SbItemCards card_props={item}/>)}
             </div></div>)
}
/* 
        <div>
         
            <div className="news-container"style={{}}>

                {Object.keys(props.cur_bok.res.content.sum_res).map(res_key=>
                        <div id="news_card_title" ><h1> Result of {res_key}:</h1><br/>
                        <SbItemCards card_props={props.cur_bok.res.content.sum_res[res_key]}/>
                        </div>
                        
                     )}
            </div>
        </div>
    )
} */