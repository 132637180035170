import { createSlice } from '@reduxjs/toolkit';

import SingleBokServices from './utils/SingleBokServices';

export const sbServices = new SingleBokServices()
const initial_invest_mode ='scen_sc4_win'

export const singleBokSlice = createSlice({
  name: 'single_bok',
  initialState: {
    sb_fetching:false,
    refreshed:false,
    explored_doc_boks:{docs_urls:['url_lists'], results:[{doc_url:'', res:{}}]},
    current_doc_bok:{doc_url:'', res:{HC:['khar 0',],graphs:{}, content:{message:['khar content',], sum_res:{}}}},
    query_list:[],
    current_doc_bok_feature:{feature:'', mode:initial_invest_mode}

  },
  reducers: {
    
    setCurrentDocBok: (state, action) =>{
      state.current_doc_bok=action.payload
      state.current_doc_bok_feature={feature:'SB', mode:initial_invest_mode}
    },
    setCurrentDocBokFeature: (state, action) =>{
      state.current_doc_bok_feature=action.payload
    },
    setCurrentDocBokMode: (state, action) =>{
      console.log(action.payload)
      state.current_doc_bok_feature.mode=action.payload
    },
    setExploredDocBoks: (state, action) =>{
        state.explored_doc_boks.docs_urls.push(action.payload.doc_url)
        state.explored_doc_boks.results.push(action.payload.res)
        state.current_doc_bok=action.payload
        state.current_doc_bok_feature={feature:'SB', mode:initial_invest_mode}
      },
    setSbFetching: (state)=> {state.sb_fetching= !state.sb_fetching},
    
  },
  
 
});


export const { 
    setCurrentDocBok,
    setSbFetching,
    setExploredDocBoks,
    setCurrentDocBokFeature,
    setCurrentDocBokMode,
  } = singleBokSlice.actions;

export const explored_doc_boks = state => state.singleBok.explored_doc_boks; // singleBok is set in teh store.js which combines all teh sattes and reduces Hamid Dec 2, 2020
console.log(' FROM SINGLE DOC EXPLORED BOKS', explored_doc_boks)
export const current_doc_bok = state => state.singleBok.current_doc_bok;
export const current_doc_bok_feature = state => state.singleBok.current_doc_bok_feature;
export const current_doc_bok_mode = state => state.singleBok.current_doc_bok_feature.mode;
export default singleBokSlice.reducer;