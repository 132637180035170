import { createSlice } from '@reduxjs/toolkit';

import NewsServices from './utils/NewsServices';

export const newsServices = new NewsServices()

export const newsSlice = createSlice({
  name: 'news',
  initialState: {
    news_fetching:false,
    refreshed:false,
    last_news:{head:{bok_name:'kharBOK', query:'kharq'}, news:{news:{HC:['khar1','khar2']}, news_items:[]}},
    explored_news:{'Global-State&':{head:{bok_name:'kharBOK', query:'kharq'}, news:{news:{HC:['khar1','khar2']}, news_items:[]}}},
    current_news:'Global-State&',
    last_updated_news_time:'',
    query_list:[],
    current_news_feature:{feature:'news', mode:'top_news'}

  },
  reducers: {
    
    setCurrentNews: (state, action) =>{
      state.current_news=action.payload
      state.current_news_feature={feature:'news', mode:'cat_news'}
    },
    setCurrentNewsFeature: (state, action) =>{
      state.current_news_feature=action.payload
    },
    
    setLastUpdatedTime: (state, action) =>{ state.last_updated_news_time =action.payload},

    setExploredNews: (state, action) =>{
        state.explored_news = { ...state.explored_news, ...action.payload.data};
        //state.explored_news[action.payload.key]=action.payload.data
        state.current_news=action.payload.key;
        state.refreshed=true;
        state.query_list.push(action.payload.key);
        state.current_news_feature={feature:'news', mode:'cat_news'}
      },
    setFetching: (state)=> {state.news_fetching= !state.news_fetching}
  },
  
 
});


export const { 
    setCurrentNews,
    setFetching,
    setExploredNews,
    setLastUpdatedTime,
    setCurrentNewsFeature,
  
  } = newsSlice.actions;

export const searched_keys = state => state.news.explored_news;

export default newsSlice.reducer;