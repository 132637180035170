import React, {useState} from 'react'
import { useDispatch,} from "react-redux";
import  { setLogedIn, setRegisterdata }from '../../master/masterSlice';
import '../static/css/signin-signup.css'
import AccountServices from "../utils/AccountService";
import {axiosInstance} from '../../../axiosApi';
//import background from '../static/media/images/undraw_snow_games_ohkc.svg'
import background from '../static/media/images/undraw_product_tour_foyt.svg';
import {HandleRegisterationModal} from '../components/modal_msg'



const accountservices = new AccountServices();



export const SignInSignup= () =>{
	const dispatch=useDispatch()
	const [modalShow, setModalShow] = useState(false)
	
    const signInhandel =()=>{
        const account_container = document.getElementById('account-container');console.log(account_container)
        if(account_container){account_container.classList.remove("right-panel-active")}
    }
    const signUphandel =()=>{
      const account_container = document.getElementById('account-container');
      console.log(account_container)
    if(account_container){account_container.classList.add("right-panel-active")}
	}

	const HandleRegisteration = async (e) => {
		e.preventDefault();
		let signup_data = new FormData(document.getElementById("signup"));
		signup_data.append("username", document.querySelector("#khar").value);
		// console.log(...signup_data);
	
		accountservices.register(signup_data).then((res) => 

			{
			console.log(res.data);
			dispatch (setRegisterdata(res.data));
			setModalShow(true);
			signInhandel(e);
		})


			}

	const HandleLogin = async(e) => {
		console.log(e)
		  e.preventDefault()
		  let uploadData3= new FormData(document.getElementById('signin'))
			try {
			  const response = await axiosInstance.post('api/accounts/token/obtain/', 
				uploadData3
			  );
			  axiosInstance.defaults.headers['Authorization'] = "JWT " + response.data.access;
			  localStorage.setItem('access_token', response.data.access);
			  localStorage.setItem('refresh_token', response.data.refresh);
			  localStorage.setItem('user',JSON.parse(atob(response.data.access.split('.')[1])).first_name)
			  dispatch(setLogedIn())

			//   dispatch(userLoggedIn(JSON.parse(atob(response.data.access.split('.')[1]))));
			  

	  
		  } catch (error) {
			alert('you are not authorized to access')
			  throw error;
		  }
		}    

	const handelEnterLogin =(e)=>{
		let code = (e.keyCode ? e.keyCode : e.which);
		if(code === 13) {const query ='&'+ e.target.value; 
		HandleLogin(e)
		}
		}
	const handelEnterRegister =(e)=>{
		let code = (e.keyCode ? e.keyCode : e.which);
		if(code === 13) {const query ='&'+ e.target.value; 
		HandleRegisteration(e)
		}
		}


    return(
		<div classNames='sci-container' style={{ height:'100vh',backgroundImage:`url(${background})`, backgroundRepeat: 'no-repeat',backgroundSize:'cover'}}>
			<div style={{width:'max-content', margin:'20px auto', textAlign:'center',}}>
				<p style={{fontSize:22, padding:'20px', color:'rgb(8, 10, 144)', backgroundColor:'#7ac408', fontFamily:'Montserrat', fontWeight:'', borderRadius:3}}>
					Please sign in if you have an account or sign up to continue here. Thanks. </p> 
				</div>
        
<div class="account-container" id="account-container">
	<div class="form-account-container sign-up-account-container">
		<form  id="signup" method="POST" action="#">
			<account-h1>Create Account</account-h1>
			<div class="social-account-container">
				<a href="#" class="social"><i class="fab fa-facebook-f"></i></a>
				<a href="#" class="social"><i class="fab fa-google-plus-g"></i></a>
				<a href="#" class="social"><i class="fab fa-linkedin-in"></i></a>
			</div>
			<span>or use your email for registration</span>
			<input type="text" placeholder="Name"  name="first_name"/>
			<input type="email" id='khar' placeholder="Email" name="email"  />
			<input type="password" placeholder="Password" name="password" onKeyUp = { e => handelEnterRegister(e)}/>
			<account-button  onClick = {(e) =>HandleRegisteration(e)}>Sign Up</account-button>

			</form>
			<HandleRegisterationModal
				show={modalShow}
				onHide={(e) => setModalShow(false)}
			/>
	</div>

	<div class="form-account-container sign-in-account-container">
		<form id='signin'>
			<account-h1>Sign in</account-h1>
			<div class="social-account-container">
				<a href="#" class="social"><i class="fab fa-facebook-f"></i></a>
				<a href="#" class="social"><i class="fab fa-google-plus-g"></i></a>
				<a href="#" class="social"><i class="fab fa-linkedin-in"></i></a>
			</div>
			<span>or use your account</span>
			<input type="email" placeholder="Email" id='username' name="username"/>
			<input type="password" placeholder="Password" name="password" onKeyUp = { e => handelEnterLogin(e)}/>
			<a href="#" style={{color:'#1385d9'}}>Forgot your password?</a>
			<account-button onClick={(e)=>{HandleLogin(e)}} >Sign In</account-button>
		</form>
	</div>
	<div class="overlay-account-container">
		<div class="overlay">
			<div class="overlay-panel overlay-left">
				<account-h1>Welcome Back!</account-h1>
				<p>To keep connected with us, please login with your account info</p>
				<account-button class="ghost" id="signIn"onClick={signInhandel}>Sign In</account-button>
			</div>
			<div class="overlay-panel overlay-right">
				<account-h1>Hello, Friend!</account-h1>
				<p>Please sign up with your name and email and start the journey with us...</p>
				<account-button class="ghost" id="signUp" onClick={signUphandel}>Sign Up</account-button>
			</div>
		</div>
	</div>
</div>

</div>
    )}