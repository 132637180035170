
import React from 'react'
import { BiUnderline } from 'react-icons/bi';

const refreshed_news_aviable= (last_updated_time)=>{
    let delta_time=Math.floor(new Date().getTime()/1000) - last_updated_time
    //console.log('TIME since last updated:', delta_time)
     return delta_time>4000? true:false;
}
const start = new Date().getTime();


const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

const TrimMyString = (string, maxLength, start = 0) => {
//Note - `start` is if I want to start after some point of the string
        if (string.length > maxLength) {
        let trimmedString = string.substr(start, maxLength)
            return (
            trimmedString.substr(
            start,
            Math.min(trimmedString.length,   trimmedString.lastIndexOf(' '))
            ) + ' ...'
            )
        }
    return string}

const CheckDateValidity =(date) =>{
    try{const date2=Date.parse(date) ; if (isNaN(date2)){console.log('date and date2 are:',date,date2)}}
    catch(e){console.log(e); return false}
}

const Highlighted = ({text = '', highlight = ''}) => {
    //console.log('highlight from inside HIghlighted:', highlight)
    if (!highlight || !highlight.trim()) {
      return <span>{text}</span>
    }
    const regex = new RegExp(`(${highlight})`, 'gi')
   /*  const parts = text.split(new RegExp(`(${highlight})`, 'gi')); */
    const parts = text.split(regex)
    return (
      <span>
         {parts.filter(part => part).map((part, i) => (
             regex.test(part) ? <mark key={i} style={{backgroundColor:"transparent", color:"#d0ac3e", textDecoration:BiUnderline}}>{part}</mark> : <span key={i}>{part}</span>
         ))}
     </span>
    )
 }
 {/* <Highlighted text="the quick brown fox jumps over the lazy dog" highlight="fox"/> */}
 const handleScroll=()=>{
   // window.scrollX(0);
    // or 
    window.scroll({top:0,behavior:'smooth'})

}

export {refreshed_news_aviable, start, capitalize, TrimMyString, CheckDateValidity, Highlighted, handleScroll}