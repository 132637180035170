import React from 'react'

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {capitalize}  from '../../news/utils/newsUtilFuncs'
import {GiArrowDunk} from 'react-icons/gi'

import{
      setFetching,isFetching,
    }from '../../master/masterSlice'

    import {
      setCurrentWebBokFeature,
    
    } from '../webBokSlice'

export const WebBokLeftNav = (props) =>{
  const dispatch= useDispatch()
  const bok_subject=props.head_query.bok_subject
  const conv_query=props.head_query.conv_query

const webhandleSearchMain = (e) =>{
  let heading_cat= document.getElementById('bok_name').textContent.trim().split(' ').join('-')
  let conv_q= document.getElementById('conv_q_l').dataset.head.trim().split(' ').join('-')
  let sub_head_cat = e.target.dataset.head.trim().split(' ').join('-')
  let query= heading_cat+'&'+conv_q+' '+sub_head_cat
  //alert('you want to search about ' + query)
  props.handelWebconverse (query)
}

const display_head_item = (item) =>{
  const hc =item.replace(/[^\w\.\s]/gi, '')
if(hc){return (<div class="sci-btn news_head_btn" data-head={item} onClick={e=>webhandleSearchMain(e)} >{hc}</div>)
}}

return(

<div id="left-nav">
<div style={{backgroundColor:"#212020", padding:"", color:"#c4c4c4", position:"sticky", fontWeight:"bold", fontSize: ""}}>
      <p>TYPE_2 INFO NUGGETS FOR:&nbsp;
      <span id="bok_name" className="main_news_head sci-btn" style={{color:"rgb(191, 196, 21)"}} onClick={e =>dispatch(setCurrentWebBokFeature({ feature:'WEB'}))} data-head={bok_subject}>{capitalize(bok_subject)}</span> 
      <span id="conv_q_l" className="main_news_head2" data-head={conv_query} style={{color:"rgb(191, 196, 21)"}}>
      <GiArrowDunk style={{display:"inline", color:'white'}} size='18px'/>
        {capitalize(conv_query)}
        </span>
         </p>
      </div>
        <div id="news_heads" style={{ }}>
        

        {props.HC.map(item =>display_head_item(item))}
        
        </div>
 
  </div>
  )}

