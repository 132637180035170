import React from 'react'

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { capitalize, TrimMyString} from  '../../news/utils/newsUtilFuncs'
//import '../../news/static/css/news_item.css'
//import '../../news/static/css/search_news.css'
//import '../../news/static/css/news_columns.css'
import '../../web_bok/static/css/webBok_Kd.css'

export const SbItemCards = (props) =>{
    const dispatch= useDispatch()
    //console.log('CURRENT NEWS from store',props)
    const item=props.card_props

    return(<div>
        {item.sc4_par? 
        <figure><a href='' target="blank" id="">
                        <div className="">
                                {item.meta.image?<div className="card-image">
                                 <img src= { item.meta.image } alt="" />
                                </div>:''}
                                {item.sc3_sen?
                                <div>
                                <figcaption style={{color:'rgb(191, 196, 21)', fontSize:".95rem"}}>{ item.sc3_sen.replace(/[^\w\s\.\%]/gi, '') } </figcaption>
                                                                                                                                           
                                   <p style={{color:'whitesmoke', padding:15}}>
                                       { TrimMyString(item.sc4_par.replace(/[^\w\s\.]/gi, ''), 380)}
                                     </p>
                                </div>:
                                <div>
                                    <figcaption style={{color:'rgb(191, 196, 21)'}}>{ TrimMyString(item.sc4_par.replace(/[^\w\s\.\%]/gi, ''), 380) } </figcaption>
                                </div>}
                                </div>
                                   </a>                    
                    </figure>:null}
                    </div>
        )}

/* 
                <div className="news-container2"style={{}}>
                <div id="news-columns">
                 
                    <figure>
                        <div className="">
                                <div className="card-image">
                                    <img src= { item.meta.image } alt="" />
                                </div>
                                <figcaption style={{fontSize:'.9rem'}}>{ item.sc3_sen }</figcaption>                                                                                                           
                                    <p style={{fontSize:".8rem", padding:15}}>{ TrimMyString(item.sc4_par, 280)}</p>
                                </div>                       
                    </figure>):''}
                </div>
                </div> */
    
           