

import axios from "axios";
import {API_URL_BASE} from "./url_settings"

// const ROOT_URL = window.location.protocol + "//" + window.location.host
// console.log(' THIS IS ROOT_URL:', ROOT_URL)
const baseURL=API_URL_BASE

export const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 75000,
  headers: {
      'Authorization': "JWT " + localStorage.getItem('access_token'),
      'Content-Type': 'application/json',
      accept: "application/json",
  }
});


axiosInstance.interceptors.response.use(
    response => response,
    error => {
      const originalRequest = error.config;
      //console.log('I am hereeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee')
      if(error.response){console.log(' error response is:', error.response)}
      if (error.response && error.response.status === 401 && error.response.statusText === "Unauthorized"
          && error.response.data.code === "token_not_valid") {
        const refreshToken = localStorage.getItem('refresh_token');
        if (refreshToken) {
          const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));
          // exp date in token is expressed in seconds, while now() returns milliseconds:
          const now = Math.ceil(Date.now() / 1000);
          if (tokenParts.exp > now) {
            return axiosInstance.post('/api/accounts/token/refresh/', {refresh: refreshToken})
              .then((response) => {
  
                localStorage.setItem('access_token', response.data.access);
                localStorage.setItem('refresh_token', response.data.refresh);
  
                axiosInstance.defaults.headers['Authorization'] = "JWT " + response.data.access;
                originalRequest.headers['Authorization'] = "JWT " + response.data.access;
  
                return axiosInstance(originalRequest);
              })
              .catch(err => {
                console.log(err)
              });
          } else {
              console.log("Refresh token is expired", tokenParts.exp, now);
              window.location.href = '/';
            }
        } else {
          console.log("no refresh token");
          window.location.href = '/';
        }
  
      } // else error is different from 401
      return Promise.reject(error);
    }
    
  );
