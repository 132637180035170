
import React from 'react'

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { capitalize, TrimMyString} from  '../news/utils/newsUtilFuncs'
//import {sbServices, setCurrentBok, setExploredBoks,setCurrentBokFeature} from './singleBokSlice'

import './static/css/predictive_state.css'


import {TopNav } from '../news/layout_components/topNav'
import { LeftNav } from '../news/layout_components/leftNav'

import {RightNav} from '../news/layout_components/rightNav'

//import {NewsItemCards} from '../news/components/newsCards'
//import { NewsHeadsTop } from '../news/components/newsHeadsAtTop'
import {NewsConverse} from '../news/components/newsConverse'
import {PsTopNav} from './components/ps_top_nav'

import {PredictiveCards} from './components/predictive items'

import {psServices,
    setCurrentPsBok,
    setExploredPsBoks,
    setCurrentPsBokFeature,
    explored_ps_boks,
    current_ps_bok,
    current_ps_bok_feature,
  
  } from './PsSlice.js'
 
import  {isFetching} from '../master/masterSlice';
import {ProgSpinner} from '../master/components/spinner_progress'

export const SciPS = () =>{
    const dispatch= useDispatch()
    //const cur_bok= useSelector(state =>state.singleBok.current_bok,  shallowEqual)
    const cur_ps_bok =useSelector(current_ps_bok)
    console.log('Cuurent ps bok is:', cur_ps_bok)
    const cur_ps_bok_feature=useSelector(current_ps_bok_feature)
    console.log('Cuurent ps bok feature is:', cur_ps_bok_feature)
    const progress=useSelector(isFetching)
    

    const render_ps_content = (cur_bok)=>{
        return( <div class="sci-container">
            {progress?<div style={{width:'30%', margin:'auto', padding:'2%'}}> {ProgSpinner()}</div>:null}
                    <PsTopNav  cur_bok={cur_bok}  />
                    {cur_ps_bok_feature.mode== 'next_state'?cur_bok.res.content.sum_res?
                    <PredictiveCards predictive_news={cur_bok.res.content} head_query={cur_bok.bok_name}/>:null:null}
                </div>)}

        return(
        <div>
            <TopNav/>
            <main className="sci-main-container">
                <LeftNav/> 
                
                {cur_ps_bok_feature.feature== 'PS'? render_ps_content(cur_ps_bok):''}

               {/*  {current_bok_feature.feature== 'maps'? <SingleBokGraphs head_query={current_bok.doc_url}/>:''}
                {current_bok_feature.feature== 'converse'? <NewsConverse head_query={current_bok.doc_url}/>:''} */}
              { cur_ps_bok.res.HC? <RightNav HC={cur_ps_bok.res.HC} head_query={cur_ps_bok.bok_name} />:''}
            </main>              
        </div>   
    )
}