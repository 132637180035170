

import React, { useState } from 'react'
import  {webServices,} from '../webBokSlice'
import { NewsHeadsTop } from '../../news/components/newsHeadsAtTop'
import {GrChat} from'react-icons/gr'
import {graph_spinner, linear_progress} from '../../news/utils/spinner_progress'
//import '../../news/static/css/converse.css'
import '../static/css/webBok_Kd.css'
import { capitalize, TrimMyString} from  '../../news/utils/newsUtilFuncs'
import glob_url_img  from '../../master/static/media/img/GLOB_img_2.jpg'

export const WebBokConverse = ({conv_props}) => {
  //console.log( 'PROPS RECIEVD INSIDE WEB BOK CONVERSE', conv_props)
    const converse=[{conv_query: conv_props.web_query.conv_query, conv_res:conv_props.res.conv_res.conv_sc3_sc2_cause}]

      const render_converse =(converse) =>{

          return(<div className="web-container">
              {converse.map((item, idx)=><div id="web-columns">
                <div id='sci-converse-client-message' key={idx}><p>{item.conv_query.q}</p></div>
                <br/>
                <div id='sci-converse-response2' >{item.conv_res.map((res, idx)=><div key={idx}> 
                <div id='sci-converse-response-item'>{item.conv_res.length>1? <span >{idx+1}:&nbsp;</span>:null}

                <figure>
                <div className=""><a href={ res.meta.link } target="blank" id="">
                    <div className="card-image">
                   {/*   <img src= { item[1].meta.image[0]} onError={(e)=>{e.target.onerror = null; e.target.src=glob_url_img}} alt="" /> */}
                   <img src= { res.meta.image[0] } onError={(e)=>{e.target.onerror = null; e.target.src=glob_url_img}} alt="" />
                    </div></a>
                    <a href= { res.meta.link } className='web_link' target="blank" ><figcaption style={{color:'rgb(191, 196, 21)', fontSize:'1rem'}}>
                        { TrimMyString(res.sc2_partial.replace(/[^\w\.\%\s]/gi, ''), 180) }<span style={{color:"whitesmoke"}} > ({new URL(res.meta.link).hostname })</span> 
                        </figcaption></a>
                                                                                                                               
                       <p style={{fontSize:"1rem",fontWeight:"bold" , color:'#a6a19f', padding:15}}>{ TrimMyString(res.sc3_sen.replace(/[^\w\.\%\s]/gi, ''), 380)}<span >
                        <a href= { res.meta.link } target="blank" className='web_link'>({ new URL(res.meta.link).hostname }) </a></span></p>
                    </div>     
                    <div class="sci-btn news_head_btn" data-head={idx}>Add To Collection</div>               
        </figure>
                </div> </div>)}
                
                </div></div>
              )}
              </div>
          )
      }
       
const converseHead = ()=>{
  return(
    <div className=''>
        <div className='sci-converse-head'><h6 style={{margin:'2px'}}> Converse With SCI:</h6> 
        <div className='sci-converse-client-input-container'>
        <div className='sci-converse-client-input'>
      <input id="converseBar" name="" type="search" required placeholder="talk about news" onKeyUp = { e => console.log(e)}/>
      </div>
      <div><GrChat  size='32px' style={{ marginLeft:'5px', marginTop:'1px',color: "#838b7d" }}/></div>
      </div></div>
      
      </div>
  )
}
 
  return(
  <div className="sci-container">
    {/* <NewsHeadsTop head_query={converse[0].conv_query} converse_head={converseHead} /> */}
      
      {converse[0].conv_query?render_converse(converse):null} 

  </div>
    
    
)

  
    }
