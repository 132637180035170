import React from 'react'

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { capitalize, TrimMyString, CheckDateValidity} from  '../../news/utils/newsUtilFuncs'
import ReactTimeAgo from 'react-time-ago'

import {GiTronArrow} from 'react-icons/gi'
const date="Fri, 11 Dec 2020 17:00:00 -0500"

export const PredictiveCards = (props) =>{
    const dispatch= useDispatch()
    // console.log('predictive NEWS item 0 from store',props.predictive_news.sum_res[0].meta)
   
    return(<div>
                <div className="news-container"style={{}}>
                   
                <div id="news-columns">
                {props.predictive_news.sum_res? props.predictive_news.sum_res.map(item => item.meta?
                    <figure><a href= { item.meta.link } target="blank" id="news_link">
                        <div className="">
                                <div className="card-image">
                                    <img src= { item.meta.image } alt="" />
                                </div><figcaption>{ item.meta.title }</figcaption>
                                <div id="news_card_content">
                                 <div> {!isNaN(Date.parse(item.meta.date))? <span title={item.meta.date} style={{fontSize:"11px"}}>
                                        <ReactTimeAgo date={item.meta.date} locale="en-US"/>:</span>:null}
                                    <span className="sci-btn" style={{ fontSize:"11px", fontWeight:'bold', fontStyle:'italic'}} > <GiTronArrow size='25px'/>{ capitalize(item.sc2_partial)}</span>
                                    </div> 
                                                                                                     
                                    <p style={{fontSize:"13px", color:'#929496'}}>{ TrimMyString(item.sc3_sen, 180)}</p>
                                </div>
                        </div>
                        </a>
                    </figure>:''):''}
                </div>
                </div>
    </div>
              )
            }