import { createSlice } from '@reduxjs/toolkit';

import WikiBokServices from './utils/wikiBokServices';

export const wikiServices = new WikiBokServices()
const initial_invest_mode ='causal_mix_q_cause_sc3'

export const wikiBokSlice = createSlice({
  name: 'wiki_bok',
  initialState: {
    wiki_fetching:false,
    refreshed:false,
    explored_wiki_boks:{subjects:['subject_lists'], results:[]},
    current_wiki_bok:{wiki_query:{bok_name:'', query:''}, HC:['wiki HEAD 0',], invst_result:{}},
    query_list:[],
    current_wiki_bok_feature:{feature:'WIKIB', mode:initial_invest_mode}

  },
  reducers: {
    
    setCurrentWikiBok: (state, action) =>{
      state.current_wiki_bok=action.payload
      state.current_wiki_bok_feature={feature:'WIKIB', mode:initial_invest_mode}
    },
    setCurrentWikiBokFeature: (state, action) =>{
      state.current_wiki_bok_feature.feature=action.payload.feature
      state.current_wiki_bok_feature.mode=initial_invest_mode
    },
    setCurrentWikiBokMode: (state, action) =>{
      console.log(action.payload)
      state.current_wiki_bok_feature.mode=action.payload
    },
    setExploredWikiBoks: (state, action) =>{
        state.explored_wiki_boks.subjects.push(action.payload.wiki_query)
        state.explored_wiki_boks.results.push(action.payload)
        state.current_wiki_bok=action.payload
        //state.refreshed=true;
        //state.query_list.push(action.payload.key);
        state.current_wiki_bok_feature={feature:'WIKIB', mode:initial_invest_mode}
      },
    setWikiFetching: (state)=> {state.wiki_fetching= !state.wiki_fetching},
    
  },
  
 
});


export const { 
    setCurrentWikiBok,
    setWikiFetching,
    setExploredWikiBoks,
    setCurrentWikiBokFeature,
    setCurrentWikiBokMode,
  } = wikiBokSlice.actions;

export const explored_wiki_boks = state => state.wikiBok.explored_wiki_boks; // wikiBok is set in teh store.js which combines all teh sattes and reduces Hamid Dec 2, 2020
export const current_wiki_bok = state => state.wikiBok.current_wiki_bok;
export const current_wiki_bok_feature = state => state.wikiBok.current_wiki_bok_feature;
export const current_wiki_bok_mode = state => state.wikiBok.current_wiki_bok_feature.mode;
export default wikiBokSlice.reducer;