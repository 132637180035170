// App.js

import React from 'react';

import {ProgressBar} from './progressBar'

export const PogressBarLinear=()=> {

    const [progress, setProgress] = React.useState(1);
    let TIME_LIMIT=60
    let DELTA_TIME=2
    const STEP = Math.ceil(DELTA_TIME/TIME_LIMIT)

    React.useEffect(() => {
      const timer = setInterval(() => {
        setProgress((prevProgress) => (prevProgress >= 100 ? 1 : prevProgress + STEP));
      }, DELTA_TIME*1000);
      return () => {
        clearInterval(timer);
      };
    }, []);
  const percentage = 73

  return (
    <div className="progressBar">
        <h1> Please be patient I am asembling a Body Of Knowledge (BOK) for you, studying, and getting ready to answer your questions shorthly:</h1>
        <ProgressBar bgcolor={"#6a1b9a"} completed={progress}/>
     {/*   <ProgressBar now={percentage} animated/> */}
    </div>
  );
}

