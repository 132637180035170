import React from 'react'
/* import { Route } from 'react-router-dom' */
import {TopNavFeatureList} from './topNavfeatureList'
import sci_news_logo  from '../static/media/img/sci-tech-news-logo_6.png'

export const TopNav = () => {
  return(
  <div className="navbar-fixed" >
  <nav className=" white-text" style={{background:'#212020'}}>
    <div id="sci_logo" style={{paddingLeft: "2.5vw"}}>
    <a href="#" className="brand-logo" style={{color: "rgb(8, 52, 119)", fontStyle: "oblique", fontWeight: 600}}>
    <img src={sci_news_logo} style={{height: 44, marginTop: 4,}}  alt="sci-tech news"/>
    </a>
    </div>
    
    <TopNavFeatureList />
  </nav>
  </div>
)

  
    }
