import { configureStore } from '@reduxjs/toolkit';
//import counterReducer from '../features/counter/counterSlice';
import newsReducer from '../features/news/newsSlice'
import masterReducer from '../features/master/masterSlice'
import singleBokReducer from '../features/singleBok/singleBokSlice'
import wikiBokReducer from '../features/wiki_kd/wikiBokSlice'
import predictiveStateReducer from '../features/predictiveStates/PsSlice'
import webBokReducer from '../features/web_bok/webBokSlice'

export default configureStore({
  reducer: {
  /*   counter: counterReducer, */
    news:newsReducer,
    master:masterReducer,
    singleBok:singleBokReducer,
    wikiBok:wikiBokReducer,
    predictiveState:predictiveStateReducer,
    webBok:webBokReducer,
  },
  devTools:false,
});
