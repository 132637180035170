

import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'

import { useSelector, useDispatch, shallowEqual } from "react-redux"
//import Dropzone from '../dropzone/Dropzone'

import '../static/css/fileUpload.css'

  export const FileUpload = (props) =>{
    const MyDropzone=()=> {
      const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
          const reader = new FileReader()
    
          reader.onabort = () => console.log('file reading was aborted')
          reader.onerror = () => console.log('file reading has failed')
          reader.onload = () => {
          // Do whatever you want with the file contents
            const binaryStr = reader.result
            console.log(binaryStr)
          }
          reader.readAsArrayBuffer(file)
        })
        
      }, [])}
      //const {getRootProps, getInputProps} = useDropzone({onDrop})
    const onDrop = useCallback(acceptedFiles => {
      // Do something with the files
    }, [])

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
  
    return (
      <div {...getRootProps({
        onClick: event => console.log(event)
      })}>
        <input {...getInputProps()} />
        {
          isDragActive ?
            <p style={{color:'white'}}>Drop the files here ...</p> :
            <p style={{color:'white'}}>Drag 'n' drop some files here, or click to select files</p>
        }
      </div>
    )
  }
  
