import { createSlice } from '@reduxjs/toolkit';

import WebBokServices from './utils/WebBokServices';

export const webServices = new WebBokServices()

const initial_invest_mode ='DETAILED_INFO_SYM_COP_ROW'
const initial_conv_mode = 'conv_sc3_sc2_cause'

export const webBokSlice = createSlice({
  name: 'web_bok',
  initialState: {
    web_fetching:false,
    refreshed:false,
    explored_web_boks:{subjects:[], results:[]},
    current_web_bok:{web_query:{bok_name:'Main query to web', query:''}, 'res':{IHC:'', HC:['web HEAD 0',], invst:{}}},
    query_list:[],
    current_web_bok_feature:{feature:'WEB', mode:initial_invest_mode},
    explored_further_web_boks:{subjects:['subject_lists'], results:[]},
    current_web_bok_conversation:{web_query:{bok_name:'', conv_query:''}, 'res':{IHC:[],HC:['web HEAD 0',], conv_res:{}}},
  },
  reducers: {
    
    setCurrentWebBok: (state, action) =>{
      state.current_web_bok=action.payload
      state.current_web_bok_feature={feature:'WEB', mode:initial_invest_mode}
    },
    setCurrentWebBokFeature: (state, action) =>{
      console.log(action.payload)
      state.current_web_bok_feature.feature=action.payload.feature
      state.current_web_bok_feature.mode=initial_invest_mode
    },
    setCurrentWebBokMode: (state, action) =>{
      console.log(action.payload)
      state.current_web_bok_feature.mode=action.payload
      state.current_web_bok_feature.feature='WEB'
    },
    setExploredWebBoks: (state, action) =>{
        state.explored_web_boks.subjects.push(action.payload.web_query)
        state.explored_web_boks.results.push(action.payload.res)
        state.current_web_bok=action.payload
        state.current_web_bok_feature={feature:'WEB', mode:initial_invest_mode}
      },
      setExploredFurtherWebBoks: (state, action) =>{
        state.explored_further_web_boks.subjects.push(action.payload.web_query)
        state.explored_further_web_boks.results.push(action.payload.res)
        state.current_web_bok=action.payload.web_query
        state.current_web_bok_feature={feature:'WEB_EXPLORE_INSIDE', mode:initial_invest_mode}
      },
      setConverseWebBoks: (state, action) =>{
        state.current_web_bok_conversation=action.payload
        state.current_web_bok_feature={feature:'CONVERSE', mode:initial_conv_mode}
      },
    setWebFetching: (state)=> {state.web_fetching= !state.web_fetching},
    
  },
  
 
});


export const { 
    setCurrentWebBok,
    setWebFetching,
    setExploredWebBoks,
    setCurrentWebBokFeature,
    setCurrentWebBokMode,
    setExploredFurtherWebBoks,
    setConverseWebBoks,
  } = webBokSlice.actions;

export const explored_web_boks = state => state.webBok.explored_web_boks; // webBok is set in teh store.js which combines all teh sattes and reduces Hamid Feb 2, 2021
export const current_web_bok = state => state.webBok.current_web_bok;
export const current_web_bok_conversation = state => state.webBok.current_web_bok_conversation;
export const current_web_bok_feature = state => state.webBok.current_web_bok_feature;
export const current_web_bok_mode = state => state.webBok.current_web_bok_feature.mode;
export default webBokSlice.reducer;