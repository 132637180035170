import { createSlice } from '@reduxjs/toolkit';

export const masterSlice = createSlice({
  name: 'master',
  initialState: {
      current_feature: 'news',
      fetching: false,
      logedin: localStorage.getItem('access_token') ? true : false,
      user_data:{user:'',email:'',  msg:'', latest_boks:''}
  },
  reducers: {
    
    setCurrentFeature: (state, action) =>{
    state.current_feature=action.payload
    },    
    setFetching: (state)=> {state.fetching= !state.fetching},
    setLogedIn: (state)=> {state.logedin= !state.logedin},
  //   setRegisterdata: (state, action) =>{
  //     // console.log('ACTION PAYLOLAD', action.payload)
  //     state.user_data = action.payload.user_data
  // },
  setUserDataLatestBoks:(state,action) => {state.user_data.latest_boks.push(action.payload)}
}
});
  
export const { 
    setCurrentFeature,
    setFetching,
    setLogedIn,
    setRegisterdata,
    setUserDataLatestBoks,
  } = masterSlice.actions;

export const currentFeature = state => state.master.current_feature;
export const isFetching = state => state.master.fetching;
export const isLogedIn = state => state.master.logedin;
export const userLatestBoks = state => state.master.user_data.latest_boks;
export default masterSlice.reducer;