
import React from 'react'
import { GrUnderline } from 'react-icons/gr';

import { useSelector, useDispatch, shallowEqual } from "react-redux";
//import * as FaIcons from 'react-icons/fa';
//import * as AiIcons from 'react-icons/ai';

import  {
    newsServices,
    setCurrentNews,
    setExploredNews,setLastUpdatedTime,setCurrentNewsFeature,
    searched_keys,
    
    } from '../newsSlice'
    
import{
        setCurrentFeature,currentFeature, setFetching,setLogedIn, isLogedIn,isFetching,
      }from '../../master/masterSlice'

import {refreshed_news_aviable, capitalize,} from  '../utils/newsUtilFuncs'

const topdivstyle ={backgroundColor:"", padding:"1px 10px",
 color:"whitesmoke",  fontWeight:"", fontSize: ".87rem", display:'flex',
 
}

const refresh_avaiable = (last_updated, handelUpdate)=>{
    return (
        refreshed_news_aviable(last_updated)? <div style={{width: '100%',marginTop:10, padding:'1px 5%', backgroundColor:"darkblue"}}> 
                <h5 style={{margin: "12px 0 12px 0", color:'white'}}> Updated News Is Avaiable... <span id="update_news_btn" className="main_news_head sci-btn" 
                onClick={handelUpdate} > Get Update News </span></h5></div>:''
    )
}

const render_top_heads = (head_list) =>{
    return( <div style={{ backgroundColor:"darkblue", marginBottom:'5'}}> <div>
        {head_list.map((hc, idx)=><div class="sci-btn news_head_btn" data-head={hc} onClick={e=>console.log(e.target.dataset.head)} >{hc}</div>)}
    </div> </div>)
}

export const NewsHeadsTop = (props) =>{
    const dispatch= useDispatch()
    const isProgress = useSelector(isFetching)
    //console.log (props)

    const current_keys = useSelector(state=>state.news.query_list, shallowEqual)
    const current_news_feature = useSelector(state =>state.news.current_news_feature,  shallowEqual)
    
    const fetchNewsSearch =(query)=>{
        console.log(query)
        if(current_keys.includes(query)){ dispatch(setCurrentNews(query));return}
        if(!isProgress){dispatch(setFetching())}
        newsServices.searchNews(query).then(res =>{
        console.log('Got result aftet search', res);
        let payload={}; payload[query]=res;
        dispatch(setExploredNews({key:query, data:payload}))
        dispatch(setFetching())
      })}

    const handleSearchMain = (e) =>{
        let heading_cat= document.getElementById('bok_name').textContent.trim().split(' ').join('-')
        let sub_head_cat = e.target.dataset.head.trim().split(' ').join('-')
        let query= heading_cat+'&'+sub_head_cat
        fetchNewsSearch(query)
    }

    const handelUpdate= () =>{dispatch(setFetching())
        newsServices.getNews('').then(news_data =>{console.log(news_data);
            let payload={}; payload['Global-State&']=news_data; dispatch(setExploredNews({key:'Global-State&', data:payload}));
            dispatch(setLastUpdatedTime(news_data.last_update))
            dispatch(setFetching())})
    }

    return(
        <div className="news-container" style={{position:"sticky", height:'auto'}}>
        {refresh_avaiable(props.last_updated, handelUpdate)}
        <div  style={topdivstyle}>
        
          <p><span id="bok_name" className="" style={{fontSize:18, fontWeight:'bold'}}  data-head={props.head_query.bok_name} onClick={e=>handleSearchMain(e)}>{capitalize(props.head_query.bok_name)}</span>
          <span id="bok_query" className="" >{props.head_query.bok_name != props.head_query.query?'/'+capitalize(props.head_query.query):''} </span></p>
          
          <div style={{width:'30%', margin:'', display:'flex',justifyContent:"center", alignItems:"" }}>
            <div style={{marginRight:'4%'}}></div>

          <div className="news_mode_btn"   data-head={''} onClick={e=>dispatch( setCurrentNewsFeature({feature:'news', mode:'cat_news'}))} >
          {current_news_feature.mode == 'cat_news'?<span style={{ color:'', textDecoration:"underline"}}> News Cats</span>:<span> News Cats</span>}
              </div>
          
          <div className="news_mode_btn"   data-head={''} onClick={e=>dispatch( setCurrentNewsFeature({feature:'news', mode:'top_related_news'}))} >
          {current_news_feature.mode == 'top_related_news'?<span style={{color:'', textDecoration:"underline"}}> Top Related News</span>:<span> Top Related News</span>}
              </div>
          
              <div  className="news_mode_btn" data-head={''} onClick={e=>dispatch( setCurrentNewsFeature({feature:'maps', mode:'1'}))} >
              {current_news_feature.feature == 'maps'?<span style={{ color:'', textDecoration:"underline"}}>  Visual Explore</span>:<span> Visual Explore</span>}
        </div>
          <div className="news_mode_btn"  data-head={''} onClick={e=>dispatch( setCurrentNewsFeature({feature:'converse',mode:'1'}))} >
          {current_news_feature.feature == 'converse'?<span style={{ color:'', textDecoration:"underline"}}> News Converse</span>:<span>News Converse</span>}
              </div>
          </div>
          <div>
       {props.converse_head?props.converse_head():null}
                  {props.graph_search_head?props.graph_search_head():null}
                  </div>
      </div>
      <div style={{backgroundColor:"whitesmock" }}>
            
              {props.HC? props.HC.map(item => <div class="sci-btn news_head_btn" data-head={item} onClick={e=>handleSearchMain(e)} >{item}</div>):''}
                  </div>
                  
    </div>

    )

}
