import React from 'react'

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { capitalize, TrimMyString} from  '../../news/utils/newsUtilFuncs'
import '../../news/static/css/news_item.css'
//import '../../news/static/css/search_news.css'
import '../static/css/webBok_Kd.css'
import glob_url_img  from '../../master/static/media/img/GLOB_img_2.jpg'

export const WebItemCards = props =>{
    const dispatch= useDispatch()
    //console.log('CURRENT web card input is',props)
    const item=props.card_props
    const idx =props.card_idx
    //const {item, idx} =props
    
    const card_img=item[1].meta.image[0]?item[1].meta.image[0]:item[0].meta.image[0]?item[0].meta.image[0]:glob_url_img
    
    const get_host_name =(url_string) =>{
        let url = new URL(url_string)
        return url.hostname
    }
    
    return(
                 
        <figure>
            <div className="">
                <a href={ item[1].meta.link } target="blank" id="">
                    <div className="card-image">
                   {/*   <img src= { item[1].meta.image[0]} onError={(e)=>{e.target.onerror = null; e.target.src=glob_url_img}} alt="" /> */}
                   <img src= { card_img } onError={(e)=>{e.target.onerror = null; e.target.src=glob_url_img}} alt="" />
                    </div></a>
                    <a href= { item[0].meta.link } className='web_link' target="blank" ><figcaption style={{color:'rgb(191, 196, 21)', fontSize:'1rem', borderBottom:''}}>
                        { TrimMyString(item[0].sc3_sen.replace(/[^\w\.\s]/gi, ''), 180) } 
                        <span style={{color:"whitesmoke"}}>({new URL(item[0].meta.link).hostname })</span> 
                         </figcaption></a>
                       
                                                                                                                               
                       <p style={{fontSize:"1rem", fontWeight:"bold" , color:'#a6a19f', padding:15}}>{ TrimMyString(item[1].sc4_par.replace(/[^\w\.\s]/gi, ''), 380)}<span >
                        <a href= { item[1].meta.link } target="blank" className='web_link'>({ new URL(item[1].meta.link).hostname }) </a></span></p>
                    </div>    
                    <div class="sci-btn news_head_btn" data-head={idx}>Add To Collection</div>
                                        
        </figure>
  )
}
/* 
    return(
                 
                    <figure>
                        <div className=""><a href={ item[1][2][0] } target="blank" id="">
                                {item[1][2][1].length<10?<div className="card-image">
                                 <img src= { item[1][2][1]} onError={(e)=>{e.target.onerror = null; e.target.src=glob_url_img}} alt="" />
                                </div>:''}</a>
                                <a href= { item[0][2][0] } className='web_link' target="blank" >({new URL(item[0][2][0]).hostname })<figcaption style={{color:'rgb(191, 196, 21)'}}>{ item[0][1].replace(/[^\w\s]/gi, '') }<span > </span> </figcaption></a>
                                                                                                                                           
                                   <p style={{fontSize:"1rem",color:'whitesmoke', padding:15}}>{ TrimMyString(item[1][1].replace(/[^\w\s]/gi, ''), 380)}<span >
                                    <a href= { item[1][2][0] } target="blank" className='web_link'>({ new URL(item[1][2][0]).hostname }) </a></span></p>
                                </div>                        
                    </figure>
              )
            } */