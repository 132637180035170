
import React , { useState,  useEffect, useRef } from 'react'

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { capitalize, TrimMyString} from  '../news/utils/newsUtilFuncs'

import {GrGraphQl, GrChat} from 'react-icons/gr';
import {GiConversation, GiArchiveResearch} from 'react-icons/gi'
import {BiConversation, BiUnderline} from 'react-icons/bi'
import './static/css/webBok_Kd.css'

//import {ProgSpinner} from '../master/components/spinner_progress'
//import {DynProgress} from '../master/components/dynamicProgress'
//import {ProgressBar} from '../master/components/progressBar'
import {PogressBarLinear} from '../master/components/linearProgressbar'

import {isFetching} from '../master/masterSlice'

import {TopNav } from '../news/layout_components/topNav'
import { LeftNav } from '../news/layout_components/leftNav'

import {RightNav} from '../news/layout_components/rightNav'

import {NewsItemCards} from '../news/components/newsCards'
import { NewsHeadsTop } from '../news/components/newsHeadsAtTop'
import {NewsConverse} from '../news/components/newsConverse'
import {WebBokConverse} from './components/web_bok_converse'
import {WebBokLeftNav} from './components/webBokLeftNav'
import {WebBokRightNav} from './components/webBokRightNav'
import {WebBokGraphs} from './components/web_bok'
import {WebBokSum} from './components/web_bok_sum'
import {TreeGraph} from '../master/components/graphComponents/newsTreeMap2'


import {webServices,
    setCurrentWebBok,
    setExploredWebBoks,
    setCurrentWebBokFeature,
    setCurrentWebBokMode,
    explored_web_boks,
    current_web_bok,
    current_web_bok_feature,
    current_web_bok_mode,
    setExploredFurtherWebBoks,
    setConverseWebBoks,
    current_web_bok_conversation,
  
  } from './webBokSlice.js'

import{
    setFetching,
 }from '../master/masterSlice'
import { WebBOKConverse } from './components/web_bok_converse';

 export const WebBOK = () =>{
    const dispatch= useDispatch()
    const progress=useSelector(isFetching)
    const cur_bok =useSelector(current_web_bok)
    const cur_conv =useSelector(current_web_bok_conversation)
    //console.log('Cuurent web bok:', cur_bok)
    console.log('Cuurent web CONVERSATION:', cur_conv)
    //console.log('Cuurent web bok keys are:', Object.keys(cur_bok))
    const cur_bok_feature=useSelector(current_web_bok_feature)
    const cur_bok_invst_mode=useSelector(current_web_bok_mode)
    console.log('Cuurent web bok feature is:', cur_bok_feature)
    console.log('Cuurent web bok MODE is:', cur_bok_invst_mode)
    
    const canvas_ref=useRef()
    //const mainRef = useRef(null)

    const active_mode_btn = (invst_mode) =>{
      if(document.querySelectorAll('.web_btn')){document.querySelectorAll('.web_btn').forEach(item =>{ 
        if (item.dataset.invstKey === invst_mode){item.style.color='rgb(127, 211, 1)'} else{item.style.color='whitesmoke'}}
      )}
    //if(mainRef.current){mainRef.current.scrollIntoView() }
  }

      useEffect(() => {    active_mode_btn(cur_bok_invst_mode) });
      
      //const handleScroll=()=>{ window.scroll({top:0,behavior:'smooth'}) }
      //const executeScroll = () => mainRef.current.scroll({top:0,behavior:'smooth'}) 
      const handleScroll=()=> {
        if (document.getElementById('web-container')){document.getElementById('web-container').scrollTo({top:0,behavior:'smooth'})}
      }
    const fetchWb = (doc_data) =>{
        console.log(doc_data)
        dispatch(setFetching())
        webServices.converseWeb(doc_data).then(resp =>{
           console.log('Got resultfrom back for single doc', resp);
           dispatch(setConverseWebBoks({'web_query':resp.web_query, 'res':resp.res}))
           dispatch(setFetching())
      })}
    const handelWebSearchFurtherEnter =(e)=>{
        let code = (e.keyCode ? e.keyCode : e.which);
      if(code === 13) {
      const query =cur_bok.res.bok_subject+'&'+ e.target.value;
      const web_further_fetch_data={'bok_subject':cur_bok.res.bok_subject, 'conv_query':e.target.value, 'BOK_RANGE':1, 'NEW_SESSION':false}
      console.log('i am going to fethh further web queryas: ', web_further_fetch_data, 'get query is', query)
      fetchWb(query);document.getElementById('web_converse').value='';
      }
    }
    const handelConverseEnter = handelWebSearchFurtherEnter

const web_tools2 = (cur_bok_subject) =>{ 
  return(<div>
    <div id ='web_tool_container'><div></div> <p style={{color:'', fontSize:18}}>Current subject under investigation:
    <span id='bok_name' style={{color:'#a2b70d', fontSize:18, fontWeight:'bold'}}>&#160;{cur_bok_subject}</span></p>
    <div className='top-nav-btn tooltip' style={{width:''}} onClick={e=>dispatch(setCurrentWebBokFeature({feature:'CONVERSE'}))} >
                  <GiArchiveResearch style={{display:"block", margin:'auto'}} size='25px'/>
                  <span style={{width:210}} class="tooltiptext">Further Research In &#160;<span style={{color:'red'}}>{cur_bok_subject}</span> </span>
      </div>
      <div className='sci-converse-client-input' style={{maxWidth:'25vw', marginLeft:'-4vw', marginRight:'3vw'}} >
      <input id ='web_converse' type='text' placeholder='Further Invesitgation Here' onKeyUp = { e =>handelWebSearchFurtherEnter(e)}/>
      </div>

      <div className='top-nav-btn tooltip' style={{width:''}} onClick={e=>dispatch(setCurrentWebBokFeature({feature:'CONVERSE'}))} >
                  <GiConversation style={{display:"block", marginLeft:'3vw'}} size='25px'/>
                  <span style={{width:210}} class="tooltiptext">Lets Talk About &#160;<span style={{color:'red'}}>{cur_bok_subject}</span> </span>
      </div>
      {cur_bok_feature.feature == 'CONVERSE'? converseHead():null}
    </div>
  </div>)
}

const web_tools = (cur_bok_res) =>{ 
  return(<div id="left-nav" style={{backgroundColor:''}}><div id="web_tools" style={{backgroundColor:'#212020', padding: ".5rem"}}>
    <div><p style={{color:"whitesmoke", fontSize:'', fontWeight:"bold", textDecoration:'underline', marginBottom:5}}>INVESTIGATION MODES:</p></div>
{Object.keys(cur_bok_res.invst).map(k_item =><div class="web_btn" id={k_item} data-invst-key={k_item} onClick=
{e =>{dispatch(setCurrentWebBokMode(e.target.dataset.invstKey));handleScroll() }}>{k_item}</div>)}</div></div>)}


const converseHead = ()=>{
  console.log(' converse switch is:', current_web_bok_feature.feature)
  return(
        <div className='sci-converse-client-input' style={{maxWidth:'25vw'}}>
      <input id="converseBar" name="" type="search" required placeholder="talk about " onKeyUp = { e => handelWebSearchFurtherEnter(e)}/>
      </div>
  )
}



        return(
        <div>
            <TopNav/>
            <main className="sci-main-container" style={{backgroundColor:'whitesmoke'}}>
              {cur_bok_feature.feature == 'WEB'?cur_bok.res!== undefined? web_tools(cur_bok.res):null:null}

                <div ref={canvas_ref} className='sci-container'>
                
                {cur_bok.res.bok_subject?web_tools2(cur_bok.res.bok_subject):null}
                {progress?<div style={{width:'70%', margin:'auto', padding:'2%'}}><PogressBarLinear /></div>:null}
                                 
                {cur_bok.res.invst[cur_bok_invst_mode]?
                <div className="sci-container" > <WebBokSum cur_items={cur_bok.res.invst[cur_bok_invst_mode]}/></div>:null}
                                
              {cur_bok_feature.feature == 'CONVERSE'?<WebBokConverse conv_props={cur_conv} />:null}

             {cur_bok_feature.feature== 'P_TREE'?<TreeGraph width={canvas_ref.current.offsetWidth} height={1100} data={cur_bok.graphs.P_TREE} />:null}
             {cur_bok_feature.feature== 'LP_TREE'?<TreeGraph width={canvas_ref.current.offsetWidth} height={1100} data={cur_bok.graphs.LP_TREE} />:null}
             {cur_bok_feature.feature== 'NIC_TREE'?<TreeGraph width={canvas_ref.current.offsetWidth} height={1100} data={cur_bok.graphs.NIC_TREE} />:null}
               {/*  {current_bok_feature.feature== 'maps'? <SingleBokGraphs head_query={current_bok.doc_url}/>:''}
                {current_bok_feature.feature== 'converse'? <NewsConverse head_query={current_bok.doc_url}/>:''} */}
              </div>
              {cur_bok.res.IHC? <WebBokRightNav HC={cur_bok.res.IHC.top_nic_pos.assoc} head_query={cur_bok.res} handelWebconverse={fetchWb} />:null}
              //
              {cur_bok_feature.feature == 'CONVERSE'?cur_conv.res.HC.sc1_cause? 
              <WebBokRightNav HC={cur_conv.res.HC.sc1_cause} head_query={cur_conv.web_query} handelWebconverse={fetchWb} />:null:null}
              //
              {cur_bok_feature.feature == 'CONVERSE'?cur_conv.res.HC.sc1_effect?
               <WebBokLeftNav HC={cur_conv.res.HC.sc1_effect} head_query={cur_conv.web_query} handelWebconverse={fetchWb}/>:null:null}
            </main>              
        </div>   
    )
}